<template>
  <div>
    <div class="logo-and-name">
      <img
        src="../../public/logo.png"
        alt=""
        style="
          width: 100px;
          height: 100px;
          border-radius: 50%;
          vertical-align: middle;
        "
      />
      <span style="font-weight: 700; font-size: 32px; margin-left: 20px"
        >糖恋友约</span
      >
      <div style="color: #c5c5c5; font-size: 14px; margin-top: 10px">
        让爱情更加甜美~
      </div>
    </div>
    <div class="retrieve-container">
      <el-form
        ref="retrieveForm"
        :model="retrieveForm"
        :rules="retrieveRules"
        class="retrieve-form"
      >
        <el-form-item prop="phone">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="retrieveForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="smsCode">
          <el-input
            prefix-icon="el-icon-message"
            v-model="retrieveForm.smsCode"
            placeholder="请输入验证码"
            style="width: 300px; margin-right: 20px"
          ></el-input>
          <el-button
            type="primary"
            :disabled="smsCodeDisabled"
            @click="sendSmsCode"
            >{{ smsCodeText }}</el-button
          >
        </el-form-item> -->
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            show-password
            v-model="retrieveForm.password"
            type="password"
            placeholder="请输入至少6位新密码，区分大小写"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verifyPassword">
          <el-input
            prefix-icon="el-icon-lock"
            show-password
            v-model="retrieveForm.verifyPassword"
            type="password"
            placeholder="确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            prefix-icon="el-icon-message"
            v-model="retrieveForm.smsCode"
            placeholder="请输入验证码"
            style="width: 300px; margin-right: 20px"
          ></el-input>
          <el-button
            type="primary"
            :disabled="smsCodeDisabled"
            @click="sendSmsCode"
            >{{ smsCodeText }}</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            class="retrieveBtn"
            type="primary"
            @click="submitForm('retrieveForm')"
            >修改密码</el-button
          >
        </el-form-item>
        <el-row>
          <el-col style="text-align: center"
            ><router-link to="/login">返回</router-link></el-col
          >
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<!-- JS代码 -->
<script>
import { sendcode, checkcodephone } from "@/api/userLogin";
export default {
  data() {
    return {
      activeTab: "password",
      smsCodeText: "获取验证码",
      retrieveForm: {
        phone: "",
        password: "",
        verifyPassword: "",
        smsCode: "",
      },
      smsCodeDisabled: false,
      retrieveRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "请输入正确的密码规范",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.retrieveForm.smsCode = "";
  },
  methods: {
    async submitForm(formName) {
      let param = {
        code: this.retrieveForm.smsCode,
        phone: this.retrieveForm.phone,
        pwd: this.retrieveForm.verifyPassword,
      };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过，提交表单
          console.log("submit form", this[formName]);
          let res = await checkcodephone(param);
          console.log(res, "修改密码");
          if ("ok" == res.data) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$router.back();
          }
        } else {
          // 表单验证不通过
          console.log("validate failed");
          return false;
        }
      });
    },
    async sendSmsCode() {
      // 发送短信验证码
      console.log("send sms code");
      this.smsCodeDisabled = true;
      let count = 60;
      if (this.retrieveForm.phone == "") {
        this.$message.error("请输入手机号");
        this.smsCodeDisabled = false;
      }
      let res = await sendcode({
        phone: this.retrieveForm.phone,
      });
      console.log(res, "sendcode");
      const timer = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timer);
          this.smsCodeText = "获取验证码";
          this.smsCodeDisabled = false;
        } else {
          this.smsCodeText = `${count}秒后重发`;
        }
      }, 1000);
    },
  },
};
</script>

<!-- CSS代码 -->
<style scoped>
.logo-and-name {
  width: 400px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
}
.retrieve-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.retrieve-tabs {
  width: 400px;
}
.retrieve-form {
  margin-top: 20px;
}
.retrieveBtn {
  padding: 12px 188px;
}
</style>

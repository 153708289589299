<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>管理员管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对管理员进行管理。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="GLatchDeletion"
      >批量删除</el-button
    >
    <el-button
      type="primary"
      style="margin-bottom: 10px"
      @click="AdministratorAdd"
      >添加管理员</el-button
    >
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 80%"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <!-- <el-table-column
        label="uni-id"
        prop="unionid"
        width="120"
        align="center"
      /> -->

      <el-table-column
        prop="nikename"
        label="昵称"
        width="200"
        align="center"
      />
      <el-table-column
        prop="acount"
        label="手机号"
        width="200"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="GLchangeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            style="background-color: red; color: #fff"
            @click="administration(scope.row)"
            >解除管理员</el-button
          >
          <el-button type="primary" @click="PglsetAdmin(scope.row)"
            >转让主管理员</el-button
          >
          <el-button type="danger" @click="GLdeleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
    <div>
      <el-dialog title="添加管理员" :visible.sync="dialogVisible" width="30%">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="管理员昵称" prop="name">
            <el-input
              prefix-icon="el-icon-user-solid"
              style="width: 400px"
              v-model="ruleForm.name"
              placeholder="请输入管理员昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              prefix-icon="el-icon-phone"
              style="width: 400px"
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              style="width: 400px"
              v-model="ruleForm.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="Addappend('ruleForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  unitgetUserList,
  GLdateUserStatus,
  GLdeleteUser,
  administration,
  GLatchDeletion,
  PglsetAdmin,
  Addappend,
} from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        phone: "",
        name: "",
        password: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入管理员昵称", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      state: 1,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
      dialogVisible: false,
    };
  },
  created() {
    this.unitgetUserList();
    console.log(sessionStorage.getItem("setUserInfo"), "66666");
  },
  methods: {
    AdministratorAdd() {
      console.log("管理员");
      this.dialogVisible = true;
    },
    // 添加管理员
    async Addappend(formName) {
      console.log(formName, "添加");
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let param = {
            phone: this.ruleForm.phone,
            pwd: this.ruleForm.password,
            name: this.ruleForm.name,
          };
          let res = await Addappend(param);
          console.log(res);
          if ("success" == res.data) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.dialogVisible = false;
            this.unitgetUserList();
          } else {
            this.$message.error(res.data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取普通用户列表
    async unitgetUserList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        stste: this.selectedUnit, //状态	true禁用	false禁用
        starNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await unitgetUserList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.unitgetUserList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.unitgetUserList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.unitgetUserList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async GLchangeStatus(row) {
      // 发送修改状态请求
      console.log("GLchangeStatus", row, row.state);
      let res = await GLdateUserStatus({ id: row.id, state: row.state });
      console.log(res);
      if (res.data == "success") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.unitgetUserList();
      } else {
        this.$message.error("操作失败");
      }
      console.log(res, "GLchangeStatus");
    },

    // 删除
    async GLdeleteUser(row) {
      console.log("GLdeleteUser", row);
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await GLdeleteUser({
            id: row.id,
          });
          console.log("GLdeleteUser", res);
          if (res.data == "success") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.unitgetUserList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 批量删除
    async GLatchDeletion() {
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将删除这些用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await GLatchDeletion({
            id: ids,
          });
          console.log(res);
          if ("success" == res.data) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.unitgetUserList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 解除管理员
    async administration(row) {
      console.log(row);
      let param = {
        id: row.id,
      };
      let res = await administration(param);
      console.log(res);
      if (res.data == "success") {
        this.$message({
          type: "success",
          message: "解除成功",
        });
        this.unitgetUserList();
      }
    },
    // 转主管理员
    async PglsetAdmin(row) {
      var setUserInfo = JSON.parse(sessionStorage.getItem("setUserInfo"));
      console.log(setUserInfo.id);
      console.log("setAdmin", row);
      let res = await PglsetAdmin({
        id: row.id,
        adminid: setUserInfo.id,
      });
      if (res.data == "success") {
        this.$message({
          type: "success",
          message: "设置成功",
        });
        this.unitgetUserList();
      } else {
        this.$message.error("设置失败");
      }
      console.log("setAdmin", res);
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

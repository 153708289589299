<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="脱单币套餐名称" prop="name" required>
        <el-input v-model="ruleForm.name" style="width: 30%"></el-input
        ><span style="font-size: 14px; color: #ccc">(格式:套餐一)</span>
      </el-form-item>
      <el-form-item label="脱单币套餐价格" prop="appmoneyricep" required>
        <el-input v-model="ruleForm.appmoneyricep" style="width: 30%"></el-input
        >元,人民币
      </el-form-item>
      <el-form-item label="购得脱单币个数" prop="time" required>
        <el-input v-model="ruleForm.time" style="width: 30%"></el-input
        >个,脱单币
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 15%; margin-top: 25px">
      <el-button
        type="primary"
        style="margin-right: 35px"
        @click="MYtabOk('ruleForm')"
        >完成</el-button
      >
      <el-button @click="back">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { NewCancelltabOk } from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        appmoneyricep: "",
        time: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入脱单币套餐名称", trigger: "blur" },
        ],
        appmoneyricep: [
          { required: true, message: "请输入脱单币套餐价格", trigger: "blur" },
        ],
        time: [
          { required: true, message: "请输入购得脱单币个数", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.$route.query;
    console.log(this.$route.query);
  },
  methods: {
    MYtabOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            name: this.ruleForm.name,
            money: this.ruleForm.appmoneyricep,
            appmoneynum: this.ruleForm.time,
          };
          let res = await NewCancelltabOk(param);
          console.log(res);
          if ("ok" == res.data) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$router.back();
          } else {
            this.$message.error("最多添加3个");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>

import axios from "axios";

const instance = axios.create({
  baseURL: "/api",
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers["Accept"] = "application/json;chartset=UTF-8;text-plain,*/*"; // 接收哪些类型的参数，前后台定，可不设置，默认是json
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>反馈预约</span>
      </div>
      <div class="text item" style="font-size: 14px">红娘对预约进行反馈。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="edtiPilian"
      >批量删除</el-button
    >

    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 90%"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        prop="userid"
        label="uni-id"
        width="200"
        align="center"
      />
      <el-table-column
        prop="wecahtname"
        label="昵称"
        width="200"
        align="center"
      />
      <el-table-column
        prop="phonenum"
        label="手机号"
        width="300"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="info" @click="YueCoureChakan(scope.row)"
            >查看信息</el-button
          >
          <el-button
            type="primary"
            :disabled="scope.row.isok == 1"
            @click="CoureChakan(scope.row)"
            >接受预约</el-button
          >
          <el-button
            :disabled="scope.row.isok == 1"
            style="background-color: red; color: #fff"
            @click="CourseDeleteUser(scope.row)"
            >拒绝预约</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  YuegetcouresList,
  YueUserStatus,
  HongDeleteUser,
  HongNoDeleteUser,
  YuedtiPilian, //批量删除
} from "@/api/userManagement";
// import { watch } from "vue";
export default {
  data() {
    return {
      loading: false,
      editUnitForm: {},
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: false,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
    };
  },
  created() {
    this.getcouresList();
  },
  methods: {
    // 获取普通用户列表
    async getcouresList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态	true禁用	false禁用
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await YuegetcouresList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getcouresList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.getcouresList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.getcouresList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await YueUserStatus({ id: row.id, state: row.state });
      console.log(res);
      if (res.data == "ok") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getcouresList();
      } else {
        this.$message.error("修改失败");
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.sendMsgData = row;

      this.dialogVisible = true;
    },
    // 查看信息
    YueCoureChakan(row) {
      console.log(row);
      this.$router.push({
        path: "/shenhePersonal",
        query: {
          row: row,
        },
      });
    },
    // 接受预约
    async CoureChakan(row) {
      var username = JSON.parse(sessionStorage.getItem("setUserInfo"));
      let param = {
        id: row.id,
        isok: 0,
        userid: row.userid,
        username: username.nikename,
      };
      let res = await HongDeleteUser(param);
      console.log(res);
      if ("操作成功" == res.data) {
        this.$message({
          type: "success",
          message: "预约成功",
        });
        this.getcouresList();
        console.log(row.isok);
      } else {
        this.$message.error("预约失败");
      }
    },
    // 拒绝预约
    async CourseDeleteUser(row) {
      var username = JSON.parse(sessionStorage.getItem("setUserInfo"));
      let param = {
        id: row.id,
        isok: 1,
        userid: row.userid,
        username: username.nikename,
      };
      let res = await HongNoDeleteUser(param);
      console.log(res);
      if ("操作成功" == res.data) {
        this.$message({
          type: "success",
          message: "拒绝成功",
        });
        this.getcouresList();
      } else {
        this.$message.error("拒绝失败");
      }
    },
    // 批量删除
    async edtiPilian() {
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      console.log(ids, "99999999999");
      this.$confirm("此操作将删除这些用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await YuedtiPilian({
          id: ids,
        });
        console.log(res);
        if ("预约成功" == res.data) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getcouresList();
        }
      });
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #ccc;
  margin-left: 43%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin-left: 43%;
}
</style>

<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 20px">
      <div class="clearfix">
        <el-row :gutter="50">
          <el-col :span="10">
            <span>课程名称:</span>
            <span class="ml-20">代号kc-003：自由创造-柔力球系统化学习</span>
          </el-col>
          <el-col :span="10">
            <span>收费模式:</span>
            <span class="ml-20">连载收费模式</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-row :gutter="20">
      <el-col :span="5"
        ><el-input
          v-model="searchText"
          placeholder="请输入章节名称关键字"
          clearable
        ></el-input
      ></el-col>
      <!-- <el-col :span="5" :offset="1">
        <span>类型:</span>&nbsp;&nbsp;
        <el-select v-model="categoryValue" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <span>收费模式:</span>&nbsp;&nbsp;
        <el-select v-model="chargeMode" placeholder="请选择">
          <el-option
            v-for="item in chargeModeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <span>状态:</span>&nbsp;&nbsp;
        <el-select v-model="selecteValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="3">
        <el-button type="primary" @click="getEducationCourseCategoryList"
          >搜索</el-button
        >
        <el-button @click="reset">重置</el-button><br />
      </el-col>
    </el-row>

    <div style="margin: 20px 0">
      <el-button type="primary" @click="newSection"
        ><i class="el-icon-circle-plus"></i> 新建章节</el-button
      >
      <!-- <el-button>批量删除</el-button> -->
    </div>

    <el-table :data="tableData" style="width: 100%" border>
      <!-- <el-table-column type="selection" fixed></el-table-column> -->
      <el-table-column prop="id" label="ID" fixed></el-table-column>
      <el-table-column prop="courseName" label="章节名称"></el-table-column>
      <el-table-column prop="" label="已有课时"></el-table-column>
      <el-table-column label="排序" align="center" width="200">
        <template>
          <i
            class="el-icon-top"
            style="
              font-size: 36px;
              font-weight: 700;
              color: red;
              margin-right: 10px;
            "
          ></i>
          <i
            class="el-icon-bottom"
            style="font-size: 36px; font-weight: 700; color: green"
          ></i>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="500px">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-input placeholder="请输入章节名称" v-model="sectionName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getEducationCourseCategoryList, deleteUser } from "@/api/courseManage";

export default {
  data() {
    return {
      dialogVisible: false,
      searchText: "",
      selecteValue: "",
      tableData: [],
      sectionName: "",
      dialogTitle: "新建章节",
      // 状态
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "上架",
        },
        {
          value: "1",
          label: "下架",
        },
      ],
      // 类型
      categoryList: [],
      categoryValue: "",
      // 收费模式
      chargeModeList: [],
      chargeMode: "",
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  created() {
    this.getEducationCourseCategoryList();
  },
  methods: {
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    // 获取课程类别列表
    async getEducationCourseCategoryList() {
      let res = await getEducationCourseCategoryList({
        name: this.searchText, //名称
        status: this.selecteValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = this.renderTime(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selecteValue = "";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    // 编辑课程类别
    handleEdit(row) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑章节";
      this.sectionName = row.courseName;
    },
    handleDelete(index, row) {
      this.tableData.splice(index, row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getEducationCourseCategoryList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 新建章节弹出层
    newSection() {
      this.dialogVisible = true;
      this.dialogTitle = "新建章节";
    },
    // 跳转课时列表
    navigationCatalogueContent() {
      this.$router.push({ path: "/catalogueContent" });
    },
  },
};
</script>

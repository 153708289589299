<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>查看动态</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对用户发布的动态进行查看。
      </div>
    </el-card>
    <el-button style="margin: 30px" type="primary" @click="back"
      >返回上一页</el-button
    >
    <div
      style="width: 40%; border: 1px solid #ccc; margin-left: 30px"
      v-loading="loading"
    >
      <el-row style="width: 80%; padding: 10px; font-size: 15px">
        <el-col :span="3">
          <img
            style="
              border: 1px solid #ccc;
              border-radius: 50%;
              width: 50px;
              height: 50px;
            "
            :src="dataList.photo"
            alt=""
          />
        </el-col>
        <el-col :span="7" style="line-height: 50px; font-weight: 600"
          >{{ dataList.nikename ? dataList.nikename : "未知"
          }}<span style="margin-left: 10px; color: red; font-weight: normal">{{
            dataList.isvip == "0" ? "" : "VIP"
          }}</span></el-col
        >
        <el-col :span="4" style="line-height: 50px"
          >{{ dataList.old ? dataList.old : "0" }}岁</el-col
        >
        <el-col :span="4" style="line-height: 50px">{{
          dataList.juzhudi ? dataList.juzhudi : "未知"
        }}</el-col>
      </el-row>
      <el-row style="padding: 10px; margin-left: 55px">{{
        dataList.alikeFriendsbook.context
          ? dataList.alikeFriendsbook.context
          : ""
      }}</el-row>
      <div
        style="padding: 10px; margin-left: 55px"
        v-for="(item, index) in dataList.alikeFriendsbook.imgurl"
        :key="index"
      >
        <img
          style="width: 250px; height: 200px"
          :src="item.imgurl ? item.imgurl : ''"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { CkanList } from "@/api/userManagement";
export default {
  data() {
    return { dataList: "", loading: false };
  },
  created() {
    this.CkanList();
  },
  methods: {
    async CkanList() {
      this.loading = true;
      let param = {
        fid: this.$route.query.id,
      };
      let res = await CkanList(param);
      console.log(res);
      this.loading = false;
      if ("200" == res.status) {
        if (!res.data.fbvo.photo) {
          res.data.fbvo.photo = "../../assets/images/noimg.png";
        }
        this.dataList = res.data.fbvo;
        console.log(this.dataList);
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

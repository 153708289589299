<template>
  <div class="headerContent">
    <div class="pageSetup">
      <!-- 导航菜单展开收起 -->
      <i
        class="el-icon-s-fold"
        style="color: #666666; font-size: 24px; cursor: pointer"
        @click="setNavMenuToggle"
      ></i>
      <!-- 刷新 -->
      <i
        class="el-icon-refresh"
        style="color: #666666; font-size: 24px; margin: 0 20px; cursor: pointer"
        @click="refresh"
      ></i>

      <!--面包屑-->
      <el-breadcrumb class="breadcrumb" separator="/">
        <!--默认给一个首页的跳转，如果不需要可以去掉-->
        <!-- <el-breadcrumb-item :to="{ path: '/dashboard' }"
          >首页</el-breadcrumb-item
        > -->
        <!--面包屑列表-->
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          @click.native="breadcrumbClick(item)"
          style="cursor: pointer"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="userSetup">
      <el-dropdown>
        <el-avatar
          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
          shape="square"
          style="vertical-align: middle; margin-right: 8px"
        ></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            ><span @click="logout">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigateItem: Array,
  },
  data() {
    return {
      navigateIndex: this.navigateItem,
      currentNavigate: "",
      breadList: [],
    };
  },
  watch: {
    $route(to) {
      console.log(to);
      this.currentNavigate = to.path;
      // this.navigateIndex.push({
      //   menuId: "",
      //   menuIndex: to.path,
      //   menuIcon: "",
      //   menuName: to.meta.title,
      // });
      this.getBreadcrumb();
    },
    navigateItem(newNavigateItem) {
      this.navigateIndex = newNavigateItem;
    },
  },
  components: {},

  created() {
    if (sessionStorage.getItem("umeetCloudVuex")) {
      this.currentNavigate = this.$route.path;
      this.navigateIndex = JSON.parse(
        sessionStorage.getItem("umeetCloudVuex")
      ).breadListState;
    }
    this.breadList = this.$store.state.breadListState;
  },

  methods: {
    // 导航菜单关闭开启切换
    setNavMenuToggle() {
      this.$store.commit("setNavMenuToggle");
    },
    // 刷新
    refresh() {
      window.location.reload();
    },
    // 面包屑点击事件
    breadcrumbClick(item) {
      if (this.currentNavigate != item.path) {
        this.$router.push({
          path: item.path,
        });
      }
    },

    // 面包屑数据处理
    getBreadcrumb() {
      let that = this;
      // 由于本项目大部分属于‘一级’页面，所以在设置路由时候，一级页面不设置breadNumber，‘二级’页面以上才设置breadNumber
      let breadNumber =
        typeof this.$route.meta.breadNumber !== "undefined"
          ? this.$route.meta.breadNumber
          : 1;
      // 获取当前页面的名字和路由，并组合成新的对象
      let newBread = { name: this.$route.name, path: this.$route.fullPath };
      let vuexBreadList = []; // 默认初始化面包屑数据
      if (breadNumber !== 1) {
        // 当前面包屑breadNumber大于1时才会从vuex中获取值
        vuexBreadList = that.$store.state.breadListState; // 获取breadList数组
      }
      if (breadNumber < vuexBreadList.length) {
        // "回退"面包屑----判断条件：当前路由breadNumber小于vuexBreadList的长度
        vuexBreadList.splice(
          breadNumber - vuexBreadList.length,
          vuexBreadList.length - breadNumber
        );
      }
      if (breadNumber > vuexBreadList.length) {
        // 添加面包屑----判断条件：当前路由breadNumber大于vuexBreadList的长度
        vuexBreadList.push(newBread);
      }
      // 处理完数据后，将最终的数据更新到vuex（用于页面刷新）
      that.$store.commit("breadListMutations", vuexBreadList);
      // 处理完数据后，将最终的数据更新为新的面包屑数组
      that.breadList = vuexBreadList;
    },
    // 退出登录
    logout() {
      console.log("logout");
      sessionStorage.clear();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.headerContent {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  border: 1px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageSetup {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.userSetup {
  margin-right: 20px;
}
</style>

import axios from "../plugins/axios";
// 单位===============
//单位管理列表
export const getEducationCompanyList = (param) =>
  axios.get(
    "/api/company/getEducationCompanyList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );

//单位管理保存
export const saveCompany = (param) => axios.post("/api/company/save", param);

//单位管理修改
export const updateCompany = (param) =>
  axios.post("/api/company/update", param);

//单位管理状态修改
export const updateCompanyStatus = (param) =>
  axios.post("/api/company/updateCompanyStatus", param);

//单位管理删除
export const deleteCompany = (param) =>
  axios.get("/api/company/deleteCompany?ids=" + param.ids);
// axios.post("/api/company/deleteCompany", { educationCloudCompany: param });

// 成员===============
//单位成员列表
export const getCompanyUserList = (param) =>
  axios.get(
    "/api/company/getCompanyUserList?companyId=" +
      param.companyId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );

//成员管理状态修改
export const updateCompanyUserStatus = (param) =>
  axios.post("/api/company/updateCompanyUserStatus", param);

//成员管理删除
export const deleteCompanyUser = (param) =>
  axios.get("/api/company/deleteCompanyUser?ids=" + param.ids);

//成员管理设为管理员
export const updateCompanyUserAdmin = (param) =>
  axios.get("/api/company/updateCompanyUserAdmin?ids=" + param.ids);

// 职位===============
//单位职位列表
export const getCompanyPositionList = (param) =>
  axios.get(
    "/api/company/getpostionlistbyid?id=" +
      param.companyId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&state=" +
      param.status
  );

//职位管理状态修改
export const updateCompanyPositionStatus = (param) =>
  // axios.post("/api/company/updateoptsionpostionlistbyid", param);
  axios.get(
    "/api/company/updateoptsionpostionlistbyid?id=" +
      param.id +
      "&state=" +
      param.state
  );

//职位管理删除
export const deleteCompanyPosition = (param) =>
  axios.get("/api/company/deleteoptsionpostionlistbyid?id=" + param.id);
// axios.post("/api/company/deleteoptsionpostionlistbyid", param);

//职位管理新增
export const saveCompanyPosition = (param) =>
  // axios.post("/api/company/addoptsionpostionlistbyid", param);
  axios.get(
    "/api/company/addoptsionpostionlistbyid?educationCloudCompanyId=" +
      param.educationCloudCompanyId +
      "&companyPosition=" +
      param.companyPosition +
      "&name=" +
      param.name
  );

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 管理员管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对管理员进行管理。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.label"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>

      <!-- <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-col> -->
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      border
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="id" label="uni-id" width="120" align="center" />
      <el-table-column prop="name" label="姓名" width="200" align="center" />
      <el-table-column
        prop="courseNum"
        label="学习课程数量"
        width="120"
        align="center"
      />
      <el-table-column
        prop="educationCloudCompany.companyName"
        label="所属单位"
        width="200"
        align="center"
      />
      <el-table-column label="联系电话" width="150" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.phone.substring(0, 3) +
            "****" +
            scope.row.phone.substring(7, 11)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="true">启用</el-radio>
            <el-radio :label="false">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="danger" @click="deleteUser(scope.row)"
            >删除</el-button
          >
          <el-button type="danger" @click="setAdmin(scope.row)"
            >解除管理员</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  getAdminUserList,
  updateUserStatus,
  deleteUser,
  setAdminOrBindAdminUser,
} from "@/api/userManagement";
export default {
  data() {
    return {
      searchText: "",
      selectedUnit: "",
      messageContent: "",
      tableData: [],
      // tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: true,
        },
        {
          label: "禁用",
          value: false,
        },
      ],
      selectedRows: [],
    };
  },
  created() {
    this.getAdminUserList();
  },
  methods: {
    // 获取管理员用户列表
    async getAdminUserList() {
      let res = await getAdminUserList({
        name: this.searchText, //名称
        status: this.selectedUnit, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      console.log(res, "getAdminUserList");
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        this.total = res.data.size;
      }
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getAdminUserList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await updateUserStatus({ id: row.id, recovery: row.recovery });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getUserList();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.dialogVisible = true;
    },
    deleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getUserList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    async setAdmin(row) {
      console.log("setAdmin", row);
      let res = await setAdminOrBindAdminUser({
        id: row.id,
        isAdmin: 0,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getAdminUserList();
      }
      console.log("setAdmin", res);
    },
    addUser() {
      console.log("addUser");
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

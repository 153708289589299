import axios from "../plugins/axios";

// 用户注册
export const checkcodephone = (param) =>
  axios.post(
    "/checkcodephone?code=" +
      param.code +
      "&phone=" +
      param.phone +
      "&pwd=" +
      param.pwd
  );

// 用户登录
export const userLoginApi = (param) =>
  axios.get(
    "/adminloginbyaccount?phone=" + param.phone + "&password=" + param.password
  );

// 用户找回密码
export const userRetrieveApi = (param) => axios.post("/", param);

// 获取验证码
export const sendcode = (param) => axios.get("/sendcode?phone=" + param.phone);

// 获取行政地区
// export const findProviceList = () => axios.get("api/county/findProviceList"); // 省
// export const findCityList = (param) =>
//   axios.get("api/county/findCityList?parentId=" + param.id); // 市
// export const findDistrictList = (param) =>
//   axios.get("api/county/findDistrictList?parentId=" + param.id); // 区
// export const findStreetList = (param) =>
//   axios.get("api/county/findStreetList?parentId=" + param.id); // 街道

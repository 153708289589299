<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>咨询页图片设置</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对咨询页面放置的图片进行设置。
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="edtiPilian"
      >批量删除</el-button
    >
    <el-button
      style="background: rgb(154, 104, 255); color: #fff; margin-bottom: 10px"
      @click="NewCoures"
      >新增项目</el-button
    >
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 60%"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        prop="name"
        label="图片名称"
        width="200"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="info" @click="CoureChakan(scope.row)"
            >查看图片</el-button
          >
          <el-button
            style="background-color: red; color: #fff"
            @click="CourseDeleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
    <!-- 查看图片弹窗 -->
    <el-dialog
      :visible.sync="editUnitDialogVisible"
      title="查看图片"
      :close-on-click-modal="false"
      style="width: 60%; margin: auto; text-align: center"
    >
      <img style="max-width: 100%" :src="editUnitForm.url" alt="" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="editUnitDialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 新增弹窗 -->
    <el-dialog
      :visible.sync="editVisible"
      title="添加图片"
      :close-on-click-modal="false"
      style="width: 80%; margin: auto"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        style="
          margin-top: 30px;
          border-bottom: 1px solid #ccc;
          width: 80%;
          padding-left: 10px;
        "
      >
        <el-form-item label="图片名称" prop="name" required>
          <el-input v-model="ruleForm.name" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="imageUrl" required>
          <!-- http://192.168.1.140:8080/api/upoimaget -->
          <el-upload
            class="avatar-uploader"
            action="https://www.zhexiwang.net/api/upoimaget"
            :show-file-list="false"
            v-model="ruleForm.imageUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.imageUrl"
              :src="ruleForm.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDel">取消</el-button>
        <el-button @click="editupload('ruleForm')">上传</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getcouresList,
  courseUserStatus,
  CourseDeleteUser,
  editupload,
  edtiPilian,
} from "@/api/userManagement";
// import { watch } from "vue";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        imageUrl: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { required: true, message: "请输入名称" },
        ],
        imageUrl: [
          { required: true, message: "请输入上传图片", trigger: "blur" },
        ],
      },
      loading: false,
      editUnitDialogVisible: false, // 查看弹出框
      editVisible: false, // 新增弹出框
      editUnitForm: {},
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: false,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
      Jkwurl: "", //上传图片路径，后端需要
    };
  },
  created() {
    this.getcouresList();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(res, file, "11111");
      this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.ruleForm.imageUrl);
      this.Jkwurl = file.response;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 上传图片
    editupload(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            url: this.Jkwurl,
            name: this.ruleForm.name,
          };
          let res = await editupload(param);
          console.log(res);
          if ("success" == res.data) {
            this.$message({
              type: "success",
              message: "上传成功",
            });
            this.editVisible = false;
            this.editDel();
            this.getcouresList();
          } else {
            this.$message.error("上传失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增项目
    NewCoures() {
      console.log("新增");
      this.editVisible = true;
    },
    editDel() {
      this.editVisible = false;
      this.ruleForm.name = "";
      this.ruleForm.imageUrl = "";
    },
    // 获取普通用户列表
    async getcouresList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态	true禁用	false禁用
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await getcouresList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getcouresList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.getcouresList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await courseUserStatus({ id: row.id, state: row.state });
      console.log(res);
      if (res.data == "success") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getcouresList();
      } else {
        this.$message.error(res.data);
        this.getcouresList();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.sendMsgData = row;

      this.dialogVisible = true;
    },
    // 查看图片
    CoureChakan(row) {
      console.log(row, "查看大图");
      this.editUnitDialogVisible = true;
      this.editUnitForm = row;
    },
    // 删除按钮
    async CourseDeleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await CourseDeleteUser({
          id: row.id,
        });
        console.log("CourseDeleteUser", res);
        if (res.data == "success") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getcouresList();
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    // 批量删除
    async edtiPilian() {
      var id = this.selectedRows.map((item) => {
        return item.id;
      });
      console.log(id);
      let param = {
        id: id,
      };
      let res = await edtiPilian(param);
      console.log(res);
      if (res.status == "200") {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.getcouresList();
      } else {
        this.$message.error("删除失败");
      }
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #ccc;
  margin-left: 43%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin-left: 43%;
}
</style>

<template>
  <div :style="videoStyle">
    <video
      class="video"
      controls="controls"
      ref="startvideo"
      @click="videoStop"
    >
      <source src="" type="video/mp4" />
    </video>
    <div class="video_btn" @click.stop="startvideo" v-show="isshow">
      <img
        class="video-img"
        src="../assets/images/u2044.png"
        alt=""
        :style="imgStyle"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoStyle: Object,
    imgStyle: Object,
  },
  data() {
    return {};
  },
  methods: {
    startvideo() {
      console.log(this.$refs.startvideo.paused, "this.$refs.startvideo.paused");
      if (this.$refs.startvideo.paused) {
        this.$refs.startvideo.play();
        this.isshow = false;
      } else {
        this.$refs.startvideo.pause();
        this.isshow = true;
      }
    },
    videoStop() {
      console.log(this.$refs.startvideo.paused, "this.$refs.startvideo.paused");
      if (!this.$refs.startvideo.paused) {
        this.isshow = true;
      }
    },
  },
};
</script>

<style scoped>
.video {
  width: 100%;
  height: 100%;
}
.video_btn {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(251, 233, 231, 0.3);
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.video-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

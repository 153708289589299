import axios from "../plugins/axios";
// 首页数据  homePageList
export const homePageList = (param) =>
  axios.get("/getsumandpeople?level=" + param.level);
// 脱单币出售情况
export const currencySingle = (param) =>
  axios.get(
    "/getOredrall?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&id=" +
      param.id
  );
//今日收益情况
export const DayrencySingle = (param) =>
  axios.get(
    "/getOredrallnewdate?page=" + param.page + "&pagesize=" + param.pagesize
  );
//添加管理员  Addappend
export const Addappend = (param) =>
  axios.get(
    "/addadminusers?phone=" +
      param.phone +
      "&pwd=" +
      param.pwd +
      "&name=" +
      param.name
  );
// 添加红娘  Addwoman
export const Addwoman = (param) =>
  axios.get(
    "/addadminredmother?phone=" +
      param.phone +
      "&pwd=" +
      param.pwd +
      "&name=" +
      param.name
  );
// 普通用户管理列表
export const getUserList = (param) =>
  axios.get(
    "/getuserlist1413?starNum=" +
      param.starNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
//查看用户个人信息
export const messageList = (param) =>
  axios.get("/getuserinfo14514?id=" + param.id);
//审核---查看用户个人信息
export const ShenmessageList = (param) =>
  axios.get("/getinfoshenhe?id=" + param.id);
// 设置设为红娘/管理员
export const setAdminOrBindAdminUser = (param) =>
  axios.get("/updateusertoadmin?id=" + param.id + "&level=" + param.level);
// 普通用户禁用与启用
export const updateUserStatus = (param) =>
  axios.get("/upupupuser?id=" + param.id + "&state=" + param.state);

// 删除普通用户按钮
export const deleteUser = (param) => axios.get("/deluser?id=" + param.id);
//批量删除
export const atchDeletion = (param) => axios.get("/deluserlist?id=" + param.id);
// 管理员用户管理列表
export const unitgetUserList = (param) =>
  axios.get(
    "/getlistadmin?starNum=" +
      param.starNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&stste=" +
      param.stste
  );
// 解除管理员/红娘同一个接口
export const administration = (param) => axios.get("/upupadmin?id=" + param.id);
//删除按钮/红娘同一个接口
export const GLdeleteUser = (param) => axios.get("/deladmin?id=" + param.id);
//批量删除/红娘同一个接口
export const GLatchDeletion = (param) =>
  axios.post("/deladminlist?id=" + param.id);
// 管理员禁用与启用
export const GLdateUserStatus = (param) =>
  axios.get("/upstateadmin?id=" + param.id + "&state=" + param.state);
//转主管理接口
export const PglsetAdmin = (param) =>
  axios.get("/updamin?id=" + param.id + "&adminid=" + param.adminid);
// 管理员用户管理列表
export const womangetUserList = (param) =>
  axios.get(
    "/getredmother?starNum=" +
      param.starNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );

// 预约红娘  YuegetcouresList
export const YuegetcouresList = (param) =>
  axios.get(
    "/yuyueloveList?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
//接受预约 HongDeleteUser
export const HongDeleteUser = (param) =>
  axios.get(
    "/redmotherisok?userid=" +
      param.userid +
      "&username=" +
      param.username +
      "&isok=" +
      param.isok +
      "&id=" +
      param.id
  );
//拒绝预约  HongNoDeleteUser
export const HongNoDeleteUser = (param) =>
  axios.get(
    "/redmotherisok?userid=" +
      param.userid +
      "&username=" +
      param.username +
      "&isok=" +
      param.isok +
      "&id=" +
      param.id
  );
//批量删除  YuedtiPilian
export const YuedtiPilian = (param) =>
  axios.get("/yuyuelovevevdellist?id=" + param.id);
// 禁用启用  YueUserStatus
export const YueUserStatus = (param) =>
  axios.get("/yuyueloveupstate?id=" + param.id + "&state=" + param.state);
// 查看图片
export const phonetu = (param) => axios.get("/getuserinfophoto?id=" + param.id);
// 咨询页图片设置  getcouresList
export const getcouresList = (param) =>
  axios.get(
    "/AZiXunImagelili?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 删除功能  CourseDeleteUser
export const CourseDeleteUser = (param) =>
  axios.get("/delAZiXunImage?id=" + param.id);
// 批量删除 edtiPilian
export const edtiPilian = (param) =>
  axios.get("/dellistAZiXunImage?id=" + param.id);
// 新增图片上传 editupload
export const editupload = (param) =>
  axios.get("/addAZiXunImage?url=" + param.url + "&name=" + param.name);
// 禁用启用  courseUserStatus
export const courseUserStatus = (param) =>
  axios.get("/uplistAZiXunImage?id=" + param.id + "&state=" + param.state);
//咨询费设置
export const courQuestionList = () => axios.get("/getzixunxiaoxi");
//咨询费设置-完成
export const Double = (param) =>
  axios.get(
    "/upzixunfeiyong?appmoney=" + param.appmoney + "&tuijian=" + param.tuijian
  );
//动态列表  DTgetUserList
export const DTgetUserList = (param) =>
  axios.get(
    "/getlistfb?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name
  );
// 查看动态 CkanList
export const CkanList = (param) => axios.get("/getfbinfo?fid=" + param.fid);
// 删除
export const earDelete = (param) => axios.get("/delfb?id=" + param.id);
// 批量删除  AllearDelete
export const AllearDelete = (param) => axios.get("/delfblist?id=" + param.id);
//控制发布动态设置
export const changeFbu = () => axios.get("/isfb1");
export const changeFbu1 = (param) =>
  axios.get("/upsetIsfb?state=" + param.state);
//控制评论设置
export const changePlun = () => axios.get("/isfb1");
export const changePlun1 = (param) =>
  axios.get("/upistoken?state=" + param.state);
//控制隐藏所有动态设置
export const changeAll = () => axios.get("/isfb1");
export const changeAll1 = (param) =>
  axios.get("/upsetShowfb?state=" + param.state);
//我的标签列表
export const tabgetList = (param) =>
  axios.get(
    "/getalllabel?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 新增项目
export const MYtabList = (param) => axios.post("/instertlabel", param);
// 删除
export const MYtabDeleteUser = (param) => axios.get("/dellabel?id=" + param.id);
// 批量删除
export const MYallDelete = (param) => axios.get("del2lebel3?id=" + param.id);
// 禁用启用   MYUserStatus
export const MYUserStatus = (param) =>
  axios.get("/uplabel?id=" + param.id + "&state=" + param.state);
// 修改完成
export const MYtabOk = (param) =>
  axios.get(
    "/uplabel?name=" +
      param.name +
      "&groupby=" +
      param.groupby +
      "&id=" +
      param.id +
      "&state=" +
      param.state
  );
//单身库轮播图列表
export const braryList = (param) =>
  axios.get(
    "/getimagezixunList?starNum=" +
      param.starNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 删除按钮
export const LibraryDeleteUser = (param) =>
  axios.get("/delzixun?id=" + param.id);
// 批量删除按钮
export const AllbraryDeleteUser = (param) =>
  axios.get("/delzixunlist?id=" + param.id);
// 禁用启用  libraryUserStatus
export const libraryUserStatus = (param) =>
  axios.get("/upstatezixun?id=" + param.id + "&state=" + param.state);
// 单身库轮播图新增  braryUpload
export const braryUpload = (param) => axios.post("/addzixun", param);
//获取用户协议列表
export const userAment = (param) =>
  axios.get(
    "/getallxieyiuser?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 修改详情
export const YHedit = (param) => axios.post("/updateu", param);
// 禁用与启用
export const YHUserStatus = (param) =>
  axios.get("/updatestateuser?id=" + param.id + "&state=" + param.state);
// 删除  XYdeleteUser
export const XYdeleteUser = (param) => axios.get("/delu?id=" + param.id);
// 批量删除  XYdeleteUser
export const XYalldeleteUser = (param) => axios.get("/delulist?id=" + param.id);
// 新增协议
export const YHcomplete = (param) => axios.post("/addu", param);

//获取隐私政策列表
export const YSgetUserList = (param) =>
  axios.get(
    "/getallxieyisimi?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 修改详情
export const YSedit = (param) => axios.post("/updates", param);
// 删除  YSdeleteUser
export const YSdeleteUser = (param) => axios.get("/dels?id=" + param.id);
// 批量删除  YSalldeleteUser
export const YSalldeleteUser = (param) => axios.get("/delslist?id=" + param.id);
// 新增隐私协议   YScomplete
export const YScomplete = (param) => axios.post("/adds", param);
// 禁用与启用
export const YSUserStatus = (param) =>
  axios.get("/updatestatesimi?id=" + param.id + "&state=" + param.state);
//获取服务协议列表
export const FWgetUserList = (param) =>
  axios.get(
    "/getallxieyifbook?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name +
      "&state=" +
      param.state
  );
// 修改详情
export const FWedit = (param) => axios.post("/updatef", param);
// 禁用与启用
export const FWUserStatus = (param) =>
  axios.get("/updatestatefbook?id=" + param.id + "&state=" + param.state);
// 删除  FWdeleteUser
export const FWdeleteUser = (param) => axios.get("/delf?id=" + param.id);
// 批量删除  FWalldeleteUser
export const FWalldeleteUser = (param) => axios.get("/delflist?id=" + param.id);
// 新增 FWcomplete
export const FWcomplete = (param) => axios.post("/addf", param);
//获取个人信息设置-审核列表
export const SHgetUserList = (param) =>
  axios.get(
    "/getuserlist?page=" +
      param.page +
      "&pagesize=" +
      param.pagesize +
      "&name=" +
      param.name
  );
//审核通过
export const SHpass = (param) => axios.get("/pass?userid=" + param.userid);
//审核不通过
export const SHnopass = (param) => axios.get("/notpass?id=" + param.id);
//批量删除
export const SHsetAdmin = (param) => axios.get("/notpasslist?id=" + param.id);
//会员设置 setMemberList
export const setMemberList = () => axios.get("/getgetget");
// 会员套餐设置  StpackaGesetup
export const StpackaGesetup = (param) =>
  axios.get("/houtaivip?name=" + param.name);
// 删除  SetdeleteUser
export const SetdeleteUser = (param) => axios.get("/delppg?id=" + param.id);
// 批量删除  PLdeleteUser
export const PLdeleteUser = (param) => axios.get("/delppglist?id=" + param.id);
// 禁用启用
export const PLUserStatus = (param) =>
  axios.get("/updatevippg?id=" + param.id + "&state=" + param.state);
// 修改信息
export const PLfoList = (param) => axios.get("/getinfovippp?id=" + param.id);
// 修改信息  PLtabOk
export const PLtabOk = (param) =>
  axios.get(
    "/getinfovippp123?id=" +
      param.id +
      "&name=" +
      param.name +
      "&time=" +
      param.time +
      "&appmoney=" +
      param.appmoney
  );
// 新增信息  MYnewtabOk
export const MYnewtabOk = (param) => axios.post("/addvippg", param);
//脱单币套餐设置  setCancelled
export const setCancelled = (param) =>
  axios.get(
    "/getaLikeappmoneypakege?name=" +
      param.name +
      "&state=" +
      param.state +
      "&pageNum=" +
      param.pageNum +
      "&pageSize=" +
      param.pageSize
  );
// 修改脱单币
export const CancelltabOk = (param) => axios.post("/updateapppackeg", param);
// 新增脱单币  NewCancelltabOk
export const NewCancelltabOk = (param) => axios.post("/addapppackeg", param);
//删除按钮  CanceSetdeleteUser
export const CanceSetdeleteUser = (param) =>
  axios.get("/delapppackeg?id=" + param.id);
//批量删除 CanceAlldeleteUser     CanceUserStatus
export const CanceAlldeleteUser = (param) =>
  axios.get("/delapppackeglist?id=" + param.id);
//批量删除  CanceUserStatus
export const CanceUserStatus = (param) =>
  axios.get("/updateapppackegstste?id=" + param.id + "&state=" + param.state);
// 脱单币赠送列表  GoldcoinList
export const GoldcoinList = (param) =>
  axios.get(
    "/getgivemoneylist?friendnum=" +
      param.friendnum +
      "&state=" +
      param.state +
      "&page=" +
      param.page +
      "&pagesize=" +
      param.pagesize
  );
// 启用禁用  修改  GoldUserStatus
export const GoldUserStatus = (param) => axios.post("/updategivemoney", param);
//删除按钮  ColddeleteUser
export const ColddeleteUser = (param) =>
  axios.get("/delgivemoney?id=" + param.id);
//删除按钮  AllGolddeleteUser
export const AllGolddeleteUser = (param) =>
  axios.get("/delgivemoneylist?id=" + param.id);
//新增项目  newColdcent
export const newColdcent = (param) => axios.post("/addgivemoney", param);

<template>
  <div>
    <el-row style="margin: 20px 0">
      <el-col :span="2" style="padding-left: 25px"
        ><span style="color: red">*</span> 协议名称</el-col
      >
      <el-col :span="13"
        ><el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="textarea1"
        >
        </el-input
      ></el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="2" style="padding-left: 25px"
        ><span style="color: red">*</span> 协议内容</el-col
      >
      <el-col :span="13"
        ><div style="border: 1px solid #ccc">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 300px; overflow-y: hidden"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @onBlur="getEditorContent"
          /></div
      ></el-col>
    </el-row>
    <el-row style="margin-left: 30%">
      <el-col :span="2"
        ><el-button type="primary" @click="complete">完成</el-button></el-col
      >
      <el-col :span="2"><el-button @click="back">取消</el-button></el-col>
    </el-row>
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { YHedit } from "@/api/userManagement";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      textarea1: "",
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 自定义上传图片的方法
            customUpload: (file, insertFn) => {
              this.uploadImage(file, insertFn);
            },
          },
          uploadVideo: {
            // 自定义上传图片的方法
            customUpload: (file, insertFn) => {
              this.uploadImage(file, insertFn);
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  created() {
    this.$route.query;
    console.log(this.$route.query);
    this.textarea1 = this.$route.query.row.name;
    this.html = this.$route.query.row.context;
  },
  methods: {
    getText(str) {
      return str
        .replace(/<[^<p>]+>/g, "") // 将所有<p>标签 replace ''
        .replace(/<[</p>$]+>/g, "") // 将所有</p>标签 replace ''
        .replace(/&nbsp;/gi, "") // 将所有 空格 replace ''
        .replace(/<[^<br/>]+>/g, ""); // 将所有 换行符 replace ''
    },
    //完成按钮
    async complete() {
      if (this.textarea1 == "") {
        this.$message.error("请输入协议名称");
        return;
      }
      if (this.getText(this.html) == "") {
        this.$message.error("请输入协议内容");
        return;
      }
      let param = {
        id: this.$route.query.row.id,
        name: this.textarea1,
        context: this.getText(this.html),
      };
      let res = await YHedit(param);
      console.log(res);
      if ("成功" == res.data) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.$router.back();
      } else {
        this.$message.error("修改失败");
      }
    },
    // 取消按钮返回上一页
    back() {
      this.$router.back();
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(editor.getAllMenuKeys()); //显示所有组件的key

      this.toolbarConfig = {
        excludeKeys: [
          "insertVideo",
          "uploadVideo",
          "insertImage",
          "deleteImage",
          "editImage",
          "group-video",
          "group-image",
          "uploadImage",
          "fullScreen",
        ],
      };
    },

    // 富文本上传图片
    uploadImage(file, insertFn) {
      console.log(file, insertFn);
    },
    getEditorContent() {
      this.$store.editorContent = this.html;
      console.log(this.$store.editorContent, "this.$store.editorContent");
    },
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style></style>

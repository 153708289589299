// 主管理
export const SUCCESS_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    // {
    //   menuId: 1,
    //   menuIndex: "/user",
    //   menuIcon: "el-icon-user",
    //   menuName: "登录",
    //   menuLevel: 2,
    //   childrens: [
    //     {
    //       menuId: 2,
    //       menuIndex: "/domesticConsumer",
    //       menuIcon: "",
    //       menuName: "首次登录信息项设置",
    //       childrens: [],
    //     },
    //   ],
    // },
    {
      menuId: 3,
      menuIndex: "/organization",
      menuIcon: "el-icon-s-custom",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 4,
          menuIndex: "/trainingAgency",
          menuIcon: "",
          menuName: "普通用户管理",
          childrens: [],
        },
        {
          menuId: 5,
          menuIndex: "/unitManagement",
          menuIcon: "",
          menuName: "管理员管理",
          childrens: [],
        },
        {
          menuId: 6,
          menuIndex: "/womanChmaker",
          menuIcon: "",
          menuName: "红娘管理",
          childrens: [],
        },
        {
          menuId: 21,
          menuIndex: "/auditFormation",
          menuIcon: "",
          menuName: "信息审核",
          childrens: [],
        },
      ],
    },
    {
      menuId: 7,
      menuIndex: "/course",
      menuIcon: "el-icon-collection",
      menuName: "咨询",
      menuLevel: 2,
      childrens: [
        {
          menuId: 8,
          menuIndex: "/courseList",
          menuIcon: "",
          menuName: "咨询页图片设置",
          childrens: [],
        },
        {
          menuId: 9,
          menuIndex: "/courQuestion",
          menuIcon: "",
          menuName: "咨询费设置",
          childrens: [],
        },
      ],
    },
    {
      menuId: 10,
      menuIndex: "/symSetting",
      menuIcon: "el-icon-s-shop",
      menuName: "单身库",
      menuLevel: 2,
      childrens: [
        {
          menuId: 11,
          menuIndex: "/setMembership",
          menuIcon: "",
          menuName: "会员设置",
          childrens: [],
        },
        {
          menuId: 12,
          menuIndex: "/setpackaGesetup",
          menuIcon: "",
          menuName: "会员套餐设置",
          childrens: [],
        },
        {
          menuId: 13,
          menuIndex: "/setLibrary",
          menuIcon: "",
          menuName: "单身库轮播图设置",
          childrens: [],
        },
        // {
        //   menuId: 14,
        //   menuIndex: "/setCommendation",
        //   menuIcon: "",
        //   menuName: "单身库嘉宾推荐设置",
        //   childrens: [],
        // },
      ],
    },
    {
      menuId: 15,
      menuIndex: "/classesManagement",
      menuIcon: "el-icon-view",
      menuName: "动态",
      menuLevel: 2,
      childrens: [
        {
          menuId: 16,
          menuIndex: "/earNings",
          menuIcon: "",
          menuName: "动态管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 17,
      menuIndex: "/SetinFormation",
      menuIcon: "el-icon-edit",
      menuName: "个人信息设置",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 18,
        //   menuIndex: "/setFormation",
        //   menuIcon: "",
        //   menuName: "个人信息项设置",
        //   childrens: [],
        // },
        {
          menuId: 19,
          menuIndex: "/setMytab",
          menuIcon: "",
          menuName: "我的标签设置",
          childrens: [],
        },
        {
          menuId: 20,
          menuIndex: "/setDmiretab",
          menuIcon: "",
          menuName: "心仪对象标签设置",
          childrens: [],
        },
        // {
        //   menuId: 21,
        //   menuIndex: "/auditFormation",
        //   menuIcon: "",
        //   menuName: "信息审核",
        //   childrens: [],
        // },
      ],
    },
    {
      menuId: 22,
      menuIndex: "/setCancelledCoin",
      menuIcon: "el-icon-help",
      menuName: "脱单币",
      menuLevel: 2,
      childrens: [
        {
          menuId: 24,
          menuIndex: "/setCancelled",
          menuIcon: "",
          menuName: "脱单币套餐设置",
          childrens: [],
        },
        {
          menuId: 25,
          menuIndex: "/setGoldcoin",
          menuIcon: "",
          menuName: "脱单币赠送设置",
          childrens: [],
        },
      ],
    },
    {
      menuId: 26,
      menuIndex: "/systemFormation",
      menuIcon: "el-icon-s-tools",
      menuName: "系统基础信息设置",
      menuLevel: 2,
      childrens: [
        {
          menuId: 27,
          menuIndex: "/userAgreement",
          menuIcon: "",
          menuName: "用户协议管理",
          childrens: [],
        },
        {
          menuId: 28,
          menuIndex: "/privacyPolicy",
          menuIcon: "",
          menuName: "隐私政策管理",
          childrens: [],
        },
        {
          menuId: 29,
          menuIndex: "/personalDynamic",
          menuIcon: "",
          menuName: "个人动态服务协议管理",
          childrens: [],
        },
      ],
    },
  ],
};
// 普通管理
export const SUCCESS_NOT_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    // {
    //   menuId: 1,
    //   menuIndex: "/user",
    //   menuIcon: "el-icon-user",
    //   menuName: "登录",
    //   menuLevel: 2,
    //   childrens: [
    //     {
    //       menuId: 2,
    //       menuIndex: "/domesticConsumer",
    //       menuIcon: "",
    //       menuName: "首次登录信息项设置",
    //       childrens: [],
    //     },
    //   ],
    // },
    {
      menuId: 3,
      menuIndex: "/organization",
      menuIcon: "el-icon-s-custom",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 4,
          menuIndex: "/putningAgency",
          menuIcon: "",
          menuName: "普通用户管理",
          childrens: [],
        },
        // {
        //   menuId: 5,
        //   menuIndex: "/unitManagement",
        //   menuIcon: "",
        //   menuName: "管理员管理",
        //   childrens: [],
        // },
        // {
        //   menuId: 6,
        //   menuIndex: "/womanChmaker",
        //   menuIcon: "",
        //   menuName: "红娘管理",
        //   childrens: [],
        // },
        {
          menuId: 21,
          menuIndex: "/auditFormation",
          menuIcon: "",
          menuName: "信息审核",
          childrens: [],
        },
      ],
    },
    {
      menuId: 7,
      menuIndex: "/course",
      menuIcon: "el-icon-collection",
      menuName: "咨询",
      menuLevel: 2,
      childrens: [
        {
          menuId: 8,
          menuIndex: "/courseList",
          menuIcon: "",
          menuName: "咨询页图片设置",
          childrens: [],
        },
        {
          menuId: 9,
          menuIndex: "/courQuestion",
          menuIcon: "",
          menuName: "咨询费设置",
          childrens: [],
        },
      ],
    },
    {
      menuId: 10,
      menuIndex: "/symSetting",
      menuIcon: "el-icon-s-shop",
      menuName: "单身库",
      menuLevel: 2,
      childrens: [
        {
          menuId: 11,
          menuIndex: "/setMembership",
          menuIcon: "",
          menuName: "会员设置",
          childrens: [],
        },
        {
          menuId: 12,
          menuIndex: "/setpackaGesetup",
          menuIcon: "",
          menuName: "会员套餐设置",
          childrens: [],
        },
        {
          menuId: 13,
          menuIndex: "/setLibrary",
          menuIcon: "",
          menuName: "单身库轮播图设置",
          childrens: [],
        },
        // {
        //   menuId: 14,
        //   menuIndex: "/setCommendation",
        //   menuIcon: "",
        //   menuName: "单身库嘉宾推荐设置",
        //   childrens: [],
        // },
      ],
    },
    {
      menuId: 15,
      menuIndex: "/classesManagement",
      menuIcon: "el-icon-view",
      menuName: "动态",
      menuLevel: 2,
      childrens: [
        {
          menuId: 16,
          menuIndex: "/earNings",
          menuIcon: "",
          menuName: "动态管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 17,
      menuIndex: "/SetinFormation",
      menuIcon: "el-icon-edit",
      menuName: "个人信息设置",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 18,
        //   menuIndex: "/setFormation",
        //   menuIcon: "",
        //   menuName: "个人信息项设置",
        //   childrens: [],
        // },
        {
          menuId: 19,
          menuIndex: "/setMytab",
          menuIcon: "",
          menuName: "我的标签设置",
          childrens: [],
        },
        {
          menuId: 20,
          menuIndex: "/setDmiretab",
          menuIcon: "",
          menuName: "心仪对象标签设置",
          childrens: [],
        },
        // {
        //   menuId: 21,
        //   menuIndex: "/auditFormation",
        //   menuIcon: "",
        //   menuName: "信息审核",
        //   childrens: [],
        // },
      ],
    },
    {
      menuId: 22,
      menuIndex: "/setCancelledCoin",
      menuIcon: "el-icon-help",
      menuName: "脱单币",
      menuLevel: 2,
      childrens: [
        {
          menuId: 24,
          menuIndex: "/setCancelled",
          menuIcon: "",
          menuName: "脱单币套餐设置",
          childrens: [],
        },
        {
          menuId: 25,
          menuIndex: "/setGoldcoin",
          menuIcon: "",
          menuName: "脱单币赠送设置",
          childrens: [],
        },
      ],
    },
    {
      menuId: 26,
      menuIndex: "/systemFormation",
      menuIcon: "el-icon-s-tools",
      menuName: "系统基础信息设置",
      menuLevel: 2,
      childrens: [
        {
          menuId: 27,
          menuIndex: "/userAgreement",
          menuIcon: "",
          menuName: "用户协议管理",
          childrens: [],
        },
        {
          menuId: 28,
          menuIndex: "/privacyPolicy",
          menuIcon: "",
          menuName: "隐私政策管理",
          childrens: [],
        },
        {
          menuId: 29,
          menuIndex: "/personalDynamic",
          menuIcon: "",
          menuName: "个人动态服务协议管理",
          childrens: [],
        },
      ],
    },
  ],
};
// 红娘
export const ERROR_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePages", //
      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 3,
      menuIndex: "/organization",
      menuIcon: "el-icon-s-custom",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 3,
          menuIndex: "/hongningAgency",
          menuIcon: "",
          menuName: "普通用户管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 4,
      menuIndex: "/course",
      menuIcon: "el-icon-collection",
      menuName: "咨询",
      menuLevel: 2,
      childrens: [
        {
          menuId: 5,
          menuIndex: "/yuyuecourseList",
          menuIcon: "",
          menuName: "反馈预约",
          childrens: [],
        },
      ],
    },
  ],
};

export default {
  SUCCESS_DATA,
  SUCCESS_NOT_DATA,
  ERROR_DATA,
};

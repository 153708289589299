<template>
  <div style="padding: 20px">
    <el-row>
      <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%">
            <strong>总注册用户数:（人）</strong>
          </div>
          <div style="color: red; margin-top: 20px">
            <strong>{{ tableData.usercount }}</strong>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%"><strong>单身嘉宾数:（人）</strong></div>
          <div style="color: red; margin-top: 20px">
            <strong>{{ tableData.danshencount }}</strong>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%">
            <strong>总管理员人数:（人）</strong>
          </div>
          <div style="color: red; margin-top: 20px">
            <strong>{{ tableData.adminsum }}</strong>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%"><strong>红娘人数:（人）</strong></div>
          <div style="color: red; margin-top: 20px">
            <strong>{{ tableData.hongsum }}</strong>
          </div>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%"><strong>会员人数:（人）</strong></div>
          <div style="color: red; margin-top: 20px"><strong>1000</strong></div>
        </div>
      </el-col> -->
      <!-- <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%">
            <strong> 脱单币售出总量:（人）</strong>
          </div>
          <div style="color: red; margin-top: 20px"><strong>1000</strong></div>
          <div
            style="color: rgb(22, 155, 213); margin-top: 10px; font-size: 12px"
            @click="Tdbdetail"
          >
            <strong>查看收益明细</strong>
          </div>
        </div>
      </el-col> -->
      <!-- <el-col :span="6">
        <div class="center">
          <div style="margin-top: 15%">
            <strong>今日收益:（人民币:元）</strong>
          </div>
          <div style="color: red; margin-top: 20px"><strong>1000</strong></div>
          <div
            style="color: rgb(22, 155, 213); margin-top: 10px; font-size: 12px"
            @click="Daydetail"
          >
            <strong>查看收益明细</strong>
          </div>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { homePageList } from "@/api/userManagement";
export default {
  data() {
    return { tableData: "" };
  },
  created() {
    this.homePageList();
  },
  methods: {
    // 获取普通用户列表
    async homePageList() {
      var level = sessionStorage.getItem("level");
      console.log(level);
      this.loading = true;
      let param = {
        level: level,
      };
      console.log(param, "param");
      let res = await homePageList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data;
      }
      console.log(this.tableData, "res");
    },
  },
};
</script>

<style>
.center {
  width: 190px;
  height: 120px;
  border: 1px solid #ccc;
  background: rgb(243, 237, 254);
  text-align: center;
  margin-bottom: 50px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import MainBody from "../views/mainBody.vue";
import HomePage from "../views/homePage/homePage.vue"; //首页
import HongHomePages from "../views/homePage/homePages.vue"; //首页
import TdbasDetail from "../views/homePage/detail/tdbDetail.vue"; //首页的脱单币明细
import DayDetail from "../views/homePage/detail/dayDetail.vue"; //首页今日明细
// import DomesticConsumer from "../views/userManagement/domesticConsumer.vue";//首次登录信息设置页面
import AdminManagement from "../views/userManagement/adminManagement.vue";
import TrainingAgency from "../views/organizationManagement/trainingAgency.vue"; //普通用户管理(主管理)
import PutningAgency from "../views/organizationManagement/putningAgency.vue"; //普通用户管理(普通管理)
import HongningAgency from "../views/organizationManagement/hongningAgency.vue"; //普通用户管理(红娘)
import UnitManagement from "../views/unitManagement/unitManagement.vue"; // 管理员管理(主管理)
import WomanChmaker from "../views/unitManagement/womanChmaker.vue"; // 红娘管理(主管理)
import MemberManagement from "../views/unitManagement/memberManagement.vue";
import JobManagement from "../views/unitManagement/jobManagement.vue";
import UnitAuthentication from "../views/authentication/unitAuthentication.vue";
import ClassesManagement from "../views/courseManagement/category/classesManagement.vue";
import NewClasses from "../views/courseManagement/category/newClasses.vue";
import EditClasses from "../views/courseManagement/category/editClasses.vue";
import CourseList from "../views/courseManagement/course/courseList.vue"; //咨询-咨询页图片设置
import YuyuecourseList from "../views/courseManagement/course/yuyuecourseList.vue"; //咨询-咨询页图片设置
import CourQuestion from "../views/courseManagement/course/courQuestion.vue"; //咨询-咨询费设置
import SetMembership from "../views/setMember/setMembership.vue"; //会员设置
import SetpackaGesetup from "../views/setMember/setpackaGesetup.vue"; //会员套餐设置
import SetMation from "../views/setMember/setMation.vue"; //会员套餐设置-修改
import NewMation from "../views/setMember/newMation.vue"; //会员套餐设置-新增
import SetLibrary from "../views/setMember/setLibrary.vue"; //单身库轮播图设置
// import SetCommendation from "../views/setMember/setCommendation.vue"; //单身库嘉宾推荐设置
import SetearNings from "../views/earNings/earNings.vue"; //动态管理
import CkanetAdmin from "../views/earNings/ckanetAdmin.vue"; //动态管理
// import SetFormation from "../views/SetinFormation/setFormation.vue"; //个人信息项设置
import SetMytab from "../views/SetinFormation/setMytab.vue"; //我的标签设置
import EditMessage from "../views/SetinFormation/editpage/editMessage.vue"; //我的标签修改信息
import NewTable from "../views/SetinFormation/editpage/newTable.vue"; //我的标签修改信息
import SetDmiretab from "../views/SetinFormation/setDmiretab.vue"; //心仪对象标签设置
import AuditFormation from "../views/SetinFormation/auditFormation.vue"; //信息审核
import SetCancelled from "../views/setCancelledCoin/setCancelled.vue"; //脱单币套餐设置
import SetCancell from "../views/setCancelledCoin/setFoin/setCancell.vue"; //脱单币套餐设置-修改
import NewCancell from "../views/setCancelledCoin/setFoin/newCancell.vue"; //脱单币套餐设置-新增
import SetGoldcoin from "../views/setCancelledCoin/setGoldcoin.vue"; //脱单币赠送设置
import SetGold from "../views/setCancelledCoin/setFoin/setGold.vue"; //脱单币赠送设置-修改
import NewGold from "../views/setCancelledCoin/setFoin/newGold.vue"; //脱单币赠送设置-新增
import UserAgreement from "../views/systemFormation/userAgreement.vue"; //用户协议管理
import ViewXqing from "../views/systemFormation/userment/viewXqing.vue"; //用户协议管理-详情
import EditXqing from "../views/systemFormation/userment/editXqing.vue"; //用户协议管理-修改
import NewXqing from "../views/systemFormation/userment/newXqing.vue"; //用户协议管理-新增
import PrivacyPolicy from "../views/systemFormation/privacyPolicy.vue"; //隐私政策管理
import ViewXinqing from "../views/systemFormation/policyment/viewXinqing.vue"; //隐私政策管理-详情
import NewXinqing from "../views/systemFormation/policyment/newXinqing.vue"; //隐私政策管理-新增
import EditXinqing from "../views/systemFormation/policyment/editXinqing.vue"; //隐私政策管理-修改
import PersonalDynamic from "../views/systemFormation/personalDynamic.vue"; //个人动态服务协议管理
import EditQing from "../views/systemFormation/dynamicment/editQing.vue"; //个人动态服务协议管理-修改
import NewQing from "../views/systemFormation/dynamicment/newQing.vue"; //个人动态服务协议管理-新增
import ViewQing from "../views/systemFormation/dynamicment/viewQing.vue"; //个人动态服务协议管理-详情
import NewCourse from "../views/courseManagement/course/newCourse.vue";
import EditCourse from "../views/courseManagement/course/editCourse.vue";
import CatalogueContent from "../views/courseManagement/catalogue/catalogueContent.vue";
import SectionList from "../views/courseManagement/catalogue/sectionList.vue";
import NewPeriod from "../views/courseManagement/catalogue/newPeriod.vue";
import EditPeriod from "../views/courseManagement/catalogue/editPeriod.vue";
import CheckPayUser from "../views/courseManagement/catalogue/checkPayUser.vue";
import CheckColckInUser from "../views/courseManagement/catalogue/checkColckInUser.vue";
import SetSplitVideo from "../views/courseManagement/catalogue/setSplitVideo.vue";
import CheckStudyData from "../views/courseManagement/study/checkStudyData.vue";
import SpecialColumn from "../views/specialTopicManagement/specialColumn.vue";
import SpecialTopic from "../views/specialTopicManagement/specialTopic.vue";
import UserLogin from "../views/userLogin.vue";
import UserRegister from "../views/userRegister.vue";
import RetrievePassword from "../views/retrievePassword.vue";
import TrainPersonal from "../views/organizationManagement/trainPersonal.vue"; //普通用户管理-个人信息  使用的id
import ShenhePersonal from "../views/SetinFormation/editpage/shenhePersonal.vue"; //审核管理-个人信息  使用的userid
import ShenPersonal from "../views/SetinFormation/editpage/shenPersonal.vue"; //审核管理-个人信息
Vue.use(VueRouter);

const routes = [
  { path: "/login", component: UserLogin },
  { path: "/register", component: UserRegister },
  { path: "/retrieve", component: RetrievePassword },
  {
    path: "/",
    component: MainBody,
    redirect: "/HomePage",
    children: [
      //首页
      {
        path: "/homePage",
        component: HomePage,
        name: "首页",
        meta: {
          requireAuth: true,
        },
      },
      //首页-红娘
      {
        path: "/homePages",
        component: HongHomePages,
        name: "首页",
        meta: {
          requireAuth: true,
        },
      },
      //首页-脱单币明细
      {
        path: "/tdbDetail",
        component: TdbasDetail,
        name: "脱单币明细",
        meta: {
          requireAuth: true,
        },
      },
      //首页-今日明细
      {
        path: "/dayDetail",
        component: DayDetail,
        name: "今日收益明细",
        meta: {
          requireAuth: true,
        },
      },

      // {
      //   path: "/domesticConsumer",
      //   component: DomesticConsumer,
      //   name: "普通用户管理",
      //   meta: {
      //     requireAuth: true,
      //   },
      // }, //普通用户管理
      {
        path: "/trainPersonal",
        component: TrainPersonal,
        name: "普通用户管理",
        meta: {
          requireAuth: true,
        },
      },
      // 主管用户管理个人信息
      {
        path: "/trainingAgency",
        component: TrainingAgency,
        name: "个人信息",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      },
      // 普通用户管理个人信息
      {
        path: "/putningAgency",
        component: PutningAgency,
        name: "个人信息",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 普通用户管理个人信息
        },
      },
      // 红娘用户管理个人信息
      {
        path: "/hongningAgency",
        component: HongningAgency,
        name: "个人信息",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 普通用户管理个人信息
        },
      },
      //培训机构管理
      {
        path: "/adminManagement",
        component: AdminManagement,
        name: "管理员管理",
        meta: {
          requireAuth: true,
        },
      },
      //管理员管理
      {
        path: "/unitManagement",
        component: UnitManagement,
        name: "管理员管理",
        meta: {
          requireAuth: true,
        },
      },
      //管理员管理
      {
        path: "/womanChmaker",
        component: WomanChmaker,
        name: "红娘管理",
        meta: {
          requireAuth: true,
        },
      },

      //咨询页图片设置
      {
        path: "/courseList",
        component: CourseList,
        name: "咨询页图片设置",
        meta: {
          requireAuth: true,
        },
      },
      //预约红娘
      {
        path: "/yuyuecourseList",
        component: YuyuecourseList,
        name: "反馈预约",
        meta: {
          requireAuth: true,
        },
      },
      //咨询页图片设置
      {
        path: "/courQuestion",
        component: CourQuestion,
        name: "咨询费设置",
        meta: {
          requireAuth: true,
        },
      },
      //会员设置
      {
        path: "/setMembership",
        component: SetMembership,
        name: "会员设置",
        meta: {
          requireAuth: true,
        },
      },
      //会员套餐设置
      {
        path: "/setpackaGesetup",
        component: SetpackaGesetup,
        name: "会员套餐设置",
        meta: {
          requireAuth: true,
        },
      },
      //修改信息
      {
        path: "/setMation",
        component: SetMation,
        name: "修改信息",
        meta: {
          requireAuth: true,
        },
      },
      //新增套餐
      {
        path: "/newMation",
        component: NewMation,
        name: "新增套餐",
        meta: {
          requireAuth: true,
        },
      },
      //单身库轮播图设置
      {
        path: "/setLibrary",
        component: SetLibrary,
        name: "单身库轮播图设置",
        meta: {
          requireAuth: true,
        },
      },
      //单身库嘉宾推荐设置
      // {
      //   path: "/setCommendation",
      //   component: SetCommendation,
      //   name: "单身库嘉宾推荐设置",
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      //动态管理
      {
        path: "/earNings",
        component: SetearNings,
        name: "单身库嘉宾推荐设置",
        meta: {
          requireAuth: true,
        },
      },
      //查看动态详情
      {
        path: "/ckanetAdmin",
        component: CkanetAdmin,
        name: "查看动态详情",
        meta: {
          requireAuth: true,
        },
      },
      //个人信息项设置
      // {
      //   path: "/setFormation",
      //   component: SetFormation,
      //   name: "个人信息项设置",
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      //我的标签设置
      {
        path: "/setMytab",
        component: SetMytab,
        name: "我的标签设置",
        meta: {
          requireAuth: true,
        },
      },
      //我的标签设置修改信息
      {
        path: "/editMessage",
        component: EditMessage,
        name: "修改信息",
        meta: {
          requireAuth: true,
        },
      },
      //我的标签设置新增
      {
        path: "/newTable",
        component: NewTable,
        name: "新增项目",
        meta: {
          requireAuth: true,
        },
      },
      //心仪对象标签设置
      {
        path: "/setDmiretab",
        component: SetDmiretab,
        name: "心仪对象标签设置",
        meta: {
          requireAuth: true,
        },
      },
      //信息审核  ShenhePersonal
      {
        path: "/auditFormation",
        component: AuditFormation,
        name: "信息审核",
        meta: {
          requireAuth: true,
        },
      },
      //信息审核-个人信息
      {
        path: "/shenPersonal",
        component: ShenPersonal,
        name: "个人信息",
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/shenhePersonal",
        component: ShenhePersonal,
        name: "个人信息",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币套餐设置
      {
        path: "/setCancelled",
        component: SetCancelled,
        name: "脱单币套餐设置",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币套餐设置-修改  NewCancell
      {
        path: "/setCancell",
        component: SetCancell,
        name: "修改信息",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币套餐设置-新增
      {
        path: "/newCancell",
        component: NewCancell,
        name: "新增信息",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币赠送设置
      {
        path: "/setGoldcoin",
        component: SetGoldcoin,
        name: "脱单币赠送设置",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币赠送设置
      {
        path: "/setGold",
        component: SetGold,
        name: "修改脱单币赠送",
        meta: {
          requireAuth: true,
        },
      },
      //脱单币赠送设置
      {
        path: "/newGold",
        component: NewGold,
        name: "新增脱单币赠送",
        meta: {
          requireAuth: true,
        },
      },
      //用户协议管理
      {
        path: "/userAgreement",
        component: UserAgreement,
        name: "用户协议管理",
        meta: {
          requireAuth: true,
        },
      },
      //用户协议管理-查看详情
      {
        path: "/viewXqing",
        component: ViewXqing,
        name: "查看详情",
        meta: {
          requireAuth: true,
        },
      },
      //用户协议管理-修改资料
      {
        path: "/editXqing",
        component: EditXqing,
        name: "查看详情",
        meta: {
          requireAuth: true,
        },
      },
      //用户协议管理-新增
      {
        path: "/newXqing",
        component: NewXqing,
        name: "新增项目",
        meta: {
          requireAuth: true,
        },
      },
      //隐私政策管理
      {
        path: "/privacyPolicy",
        component: PrivacyPolicy,
        name: "隐私政策管理",
        meta: {
          requireAuth: true,
        },
      },
      //隐私政策管理
      {
        path: "/viewXinqing",
        component: ViewXinqing,
        name: "隐私政策管理",
        meta: {
          requireAuth: true,
        },
      },
      //隐私政策管理
      {
        path: "/newXinqing",
        component: NewXinqing,
        name: "隐私政策管理",
        meta: {
          requireAuth: true,
        },
      },
      //隐私政策管理
      {
        path: "/editXinqing",
        component: EditXinqing,
        name: "隐私政策管理",
        meta: {
          requireAuth: true,
        },
      },
      //个人动态服务协议管理
      {
        path: "/personalDynamic",
        component: PersonalDynamic,
        name: "个人动态服务协议管理",
        meta: {
          requireAuth: true,
        },
      },
      //个人动态服务协议管理
      {
        path: "/editQing",
        component: EditQing,
        name: "个人动态服务协议管理",
        meta: {
          requireAuth: true,
        },
      },
      //个人动态服务协议管理
      {
        path: "/newQing",
        component: NewQing,
        name: "个人动态服务协议管理",
        meta: {
          requireAuth: true,
        },
      },
      //个人动态服务协议管理
      {
        path: "/viewQing",
        component: ViewQing,
        name: "个人动态服务协议管理",
        meta: {
          requireAuth: true,
        },
      },

      //单位管理
      {
        path: "/memberManagement",
        component: MemberManagement,
        name: "成员管理",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 二级路由
        },
      }, //成员管理
      {
        path: "/jobManagement",
        component: JobManagement,
        name: "职位管理",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 二级路由
        },
      }, //职位管理
      {
        path: "/unitAuthentication",
        component: UnitAuthentication,
        name: "单位认证",
        meta: {
          requireAuth: true,
        },
      }, //单位认证
      {
        path: "/classesManagement",
        component: ClassesManagement,
        name: "课程类别管理",
        meta: {
          requireAuth: true,
        },
      }, //课程类别管理
      {
        path: "/newClasses",
        component: NewClasses,
        name: "新建课程类别",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 课程类别新建
        },
      }, //课程类别新建
      {
        path: "/editClasses",
        component: EditClasses,
        name: "编辑课程类别",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 课程类别编辑
        },
      },
      //课程列表管理
      {
        path: "/newCourse",
        component: NewCourse,
        name: "创建课程",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //创建课程
      {
        path: "/editCourse",
        component: EditCourse,
        name: "修改课程",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //修改课程
      {
        path: "/catalogueContent",
        component: CatalogueContent,
        name: "课时列表",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //课时列表
      {
        path: "/newPeriod",
        component: NewPeriod,
        name: "创建课时",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //创建课时
      {
        path: "/editPeriod",
        component: EditPeriod,
        name: "编辑课时",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //编辑课时
      {
        path: "/checkPayUser",
        component: CheckPayUser,
        name: "查看付费用户",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //查看付费用户
      {
        path: "/checkColckInUser",
        component: CheckColckInUser,
        name: "查看打卡用户",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //查看打卡用户
      {
        path: "/setSplitVideo",
        component: SetSplitVideo,
        name: "设置分解视频",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //设置分解视频
      {
        path: "/sectionList",
        component: SectionList,
        name: "设置章节目录",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //设置章节目录
      {
        path: "/checkStudyData",
        component: CheckStudyData,
        name: "查看学习数据",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //查看学习数据
      {
        path: "/specialColumn",
        component: SpecialColumn,
        name: "专栏管理",
        meta: {
          requireAuth: true,
        },
      }, //专栏管理
      {
        path: "/specialTopic",
        component: SpecialTopic,
        name: "专题管理",
        meta: {
          requireAuth: true,
        },
      }, //专题管理
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const setUserInfo = sessionStorage.getItem("setUserInfo");
  if (to.path === "/homePage") {
    next();
    return;
  }
  if (
    !setUserInfo &&
    (to.path === "/login" || to.path === "/register" || to.path === "/retrieve")
  ) {
    next();
  } else if (
    !setUserInfo &&
    (to.path !== "/login" || to.path === "/register" || to.path === "/retrieve")
  ) {
    next("/login");
  }
  next();
});
export default router;

<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 20px">
      <div class="clearfix">
        <el-row :gutter="50">
          <el-col :span="10">
            <span>对应课时名称:</span>
            <span class="ml-20">手法动作之一：太极云手式</span>
          </el-col>
          <el-col :span="10">
            <span>收费金额:</span>
            <span class="ml-20">￥5.00</span>
          </el-col>
        </el-row>
        <el-row :gutter="50" style="margin-top: 20px">
          <el-col :span="10">
            <span>课程名称:</span>
            <span class="ml-20">代号kc-003：自由创造-柔力球系统化学习</span>
          </el-col>
          <el-col :span="10">
            <span>收费模式:</span>
            <span class="ml-20">连载收费模式</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-row :gutter="20">
      <el-col :span="5"
        ><el-input
          v-model="searchText"
          placeholder="请输入关键字"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="8" :offset="1" style="display: flex; align-items: center">
        <span>付费时间：</span>
        <el-date-picker
          v-model="payTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getEducationCourseCategoryList"
          >搜索</el-button
        >
        <el-button @click="reset">重置</el-button><br />
      </el-col>
    </el-row>

    <div style="margin: 20px 0">
      <!-- <el-button type="primary" @click="newSection"
        ><i class="el-icon-circle-plus"></i> 新建章节</el-button
      >
      <el-button>批量删除</el-button> -->
    </div>

    <el-table :data="tableData" style="width: 100%" border>
      <!-- <el-table-column type="selection" fixed></el-table-column> -->
      <el-table-column prop="id" label="ID" fixed></el-table-column>
      <el-table-column prop="courseName" label="微信名"></el-table-column>
      <el-table-column prop="" label="付费时间"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getEducationCourseCategoryList, deleteUser } from "@/api/courseManage";

export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTime: "",
      dialogVisible: false,
      searchText: "",
      selecteValue: "",
      tableData: [],
      sectionName: "",
      dialogTitle: "新建章节",
      // 状态
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "上架",
        },
        {
          value: "1",
          label: "下架",
        },
      ],
      // 类型
      categoryList: [],
      categoryValue: "",
      // 收费模式
      chargeModeList: [],
      chargeMode: "",
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  created() {
    this.getEducationCourseCategoryList();
  },
  methods: {
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    // 获取课程类别列表
    async getEducationCourseCategoryList() {
      let res = await getEducationCourseCategoryList({
        name: this.searchText, //名称
        status: this.selecteValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = this.renderTime(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selecteValue = "";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    // 编辑课程类别
    handleEdit(row) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑章节";
      this.sectionName = row.courseName;
    },
    handleDelete(index, row) {
      this.tableData.splice(index, row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getEducationCourseCategoryList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 新建章节弹出层
    newSection() {
      this.dialogVisible = true;
      this.dialogTitle = "新建章节";
    },
  },
};
</script>
<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>

<template>
  <div style="width: 100%">
    <el-row>
      <el-col :span="5"
        ><el-input
          v-model="searchText"
          placeholder="请输入课程名称/课程关键字"
          clearable
        ></el-input
      ></el-col>
      <!-- <el-col :span="5">
        <span>类型:</span>&nbsp;&nbsp;
        <el-select v-model="categoryValue" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <span>收费模式:</span>&nbsp;&nbsp;
        <el-select v-model="chargeMode" placeholder="请选择">
          <el-option
            v-for="item in chargeModeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="6" :offset="1">
        <span>上架状态:</span>&nbsp;&nbsp;
        <el-select v-model="selecteValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span>课时类型:</span>&nbsp;&nbsp;
        <el-select v-model="selecteValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getEducationCourseCategoryList"
          >搜索</el-button
        >
        <el-button @click="reset">重置</el-button><br />
      </el-col>
    </el-row>

    <div style="margin: 20px 0">
      <el-button type="primary" @click="skipNewPeriod"
        ><i class="el-icon-circle-plus"></i> 新建课时</el-button
      >
      <!-- <el-button>批量删除</el-button> -->
    </div>

    <template v-if="chargeMode == '0'">
      <el-table :data="tableData" style="width: 100%" border>
        <!-- <el-table-column type="selection" fixed></el-table-column> -->
        <el-table-column
          prop="id"
          label="ID"
          width="100"
          fixed
        ></el-table-column>
        <el-table-column
          prop="courseName"
          label="课时名称"
          width="200"
          fixed
        ></el-table-column>
        <el-table-column
          prop="inTime"
          label="创建时间"
          width="200"
        ></el-table-column>
        <el-table-column prop="" label="课时类型" width="150"></el-table-column>

        <el-table-column label="上架状态" width="100">
          <template>
            <el-button type="text">上架</el-button>
            <el-button type="text">下架</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="收费金额(￥)"
          width="150"
        ></el-table-column>
        <el-table-column label="已付费人数" width="100">
          <template>
            <span style="margin-right: 15px">100</span>
            <el-button type="text" @click="navigationCheckPayUser"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="已打卡人数" width="100">
          <template>
            <span style="margin-right: 15px">100</span>
            <el-button type="text" @click="navigationCheckColckInUser"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="已有评价" width="100">
          <template>
            <span style="margin-right: 15px">100</span>
            <el-button type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="400">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="navigationSetSplitVideo(scope.row)"
              >设置分解视频</el-button
            >
            <el-button type="primary" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="chargeMode == '1'">
      <el-table :data="tableData" style="width: 100%" border>
        <!-- <el-table-column type="selection" fixed></el-table-column> -->
        <el-table-column
          prop="id"
          label="ID"
          width="100"
          fixed
        ></el-table-column>
        <el-table-column
          prop="courseName"
          label="课时名称"
          width="200"
          fixed
        ></el-table-column>
        <el-table-column
          prop="inTime"
          label="创建时间"
          width="200"
        ></el-table-column>
        <el-table-column prop="" label="课时类型" width="150"></el-table-column>

        <el-table-column label="上架状态" width="150">
          <template>
            <el-button type="text">上架</el-button>
            <el-button type="text">下架</el-button>
          </template>
        </el-table-column>
        <el-table-column label="已打卡人数" width="150">
          <template>
            <span style="margin-right: 15px">100</span>
            <el-button type="text" @click="navigationCheckColckInUser"
              >查看</el-button
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              @click="navigationCatalogueContent(scope.row)"
              >设置分解视频</el-button
            > -->
            <el-button type="primary" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getEducationCourseCategoryList, deleteUser } from "@/api/courseManage";

export default {
  props: {
    chargeMode: String,
  },
  data() {
    return {
      searchText: "",
      selecteValue: "",
      tableData: [],
      // 状态
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "上架",
        },
        {
          value: "1",
          label: "下架",
        },
      ],
      // 类型
      categoryList: [],
      categoryValue: "",
      // 收费模式
      chargeModeList: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  created() {
    this.getEducationCourseCategoryList();
  },
  methods: {
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    // 获取课程类别列表
    async getEducationCourseCategoryList() {
      let res = await getEducationCourseCategoryList({
        name: this.searchText, //名称
        status: this.selecteValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = this.renderTime(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selecteValue = "";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    // 编辑课程类别
    handleEdit(row) {
      console.log(row);
      this.$router.push({ path: "/editPeriod" });
      sessionStorage.setItem("unitData", JSON.stringify(row));
    },
    handleDelete(index, row) {
      this.tableData.splice(index, row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getEducationCourseCategoryList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 跳转新建课时
    skipNewPeriod() {
      this.$router.push({ path: "/newPeriod" });
    },
    // 跳转编辑课时
    skipEditPeriod() {
      this.$router.push({ path: "/editPeriod" });
    },
    // 跳转设置分解视频
    navigationSetSplitVideo() {
      this.$router.push({ path: "/setSplitVideo" });
    },
    // 跳转查看付费用户
    navigationCheckPayUser() {
      this.$router.push({ path: "/checkPayUser" });
    },
    // 跳转查看打卡用户
    navigationCheckColckInUser() {
      this.$router.push({ path: "/checkColckInUser" });
    },
  },
};
</script>

<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 300px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onBlur="getEditorContent"
    />
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 自定义上传图片的方法
            customUpload: (file, insertFn) => {
              this.uploadImage(file, insertFn);
            },
          },
          uploadVideo: {
            // 自定义上传图片的方法
            customUpload: (file, insertFn) => {
              this.uploadImage(file, insertFn);
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(editor.getAllMenuKeys()); //显示所有组件的key

      this.toolbarConfig = {
        excludeKeys: [
          "insertVideo",
          "uploadVideo",
          "insertImage",
          "deleteImage",
          "editImage",
          "group-video",
          "group-image",
          "uploadImage",
          "fullScreen",
        ],
      };
    },

    // 富文本上传图片
    uploadImage(file, insertFn) {
      console.log(file, insertFn);
      // var url = "cho/common/QiniuUpToken?suffix=" + file.name;
      // request(url).then((res) => {
      //   this.uploadData.token = res.data.token;
      //   axios({
      //     url: "https://upload-z1.qiniup.com",
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //     data: {
      //       token: res.data.token,
      //       file: file,
      //     },
      //   }).then((res) => {
      //     this.onCreated(res.data.key);
      //     insertFn(this.upLoadUrl);
      //   });
      // });
    },
    getEditorContent() {
      this.$store.editorContent = this.html;
      console.log(this.$store.editorContent, "this.$store.editorContent");
    },
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style></style>

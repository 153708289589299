<template>
  <router-view></router-view>
</template>

<script>
// import wxlogin from "vue-wxlogin";
export default {
  // components: {
  //   wxlogin,
  // },
  watch: {
    $route(to) {
      console.log(to, "app");
      // this.navigateIndex.push({
      //   menuId: "",
      //   menuIndex: to.path,
      //   menuIcon: "",
      //   menuName: to.meta.title,
      // });
    },
  },
  created() {
    if (sessionStorage.getItem("currentNavigate")) {
      this.$router.push({ path: sessionStorage.getItem("currentNavigate") });
      sessionStorage.removeItem("currentNavigate");
    }
    this.$store.state.breadListState.push({
      name: this.$route.name,
      path: this.$route.fullPath,
    });
    // 在页面加载时读取sessionStorage里的状态信息
    sessionStorage.getItem("umeetCloudVuex") &&
      this.$store.replaceState(
        Object.assign(
          this.$store.state,
          JSON.parse(sessionStorage.getItem("umeetCloudVuex"))
        )
      );
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "umeetCloudVuex",
        JSON.stringify(this.$store.state)
      );
    });
  },
};
</script>

<style></style>

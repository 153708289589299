import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navMenuToggle: false, //导航菜单展开关闭切换
    breadListState: [], // 面包屑列表数据
    userInfo: "", // 用户数据
    editorContent: "", // 富文本内容
  },
  getters: {},
  mutations: {
    //导航菜单展开关闭切换
    setNavMenuToggle(state) {
      state.navMenuToggle = !state.navMenuToggle;
    },
    // 面包屑列表数据
    breadListMutations(state, list) {
      state.breadListState = list;
    },
    // 用户数据
    setUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {},
  modules: {},
});

<template>
  <div id="set-split-video">
    <el-card>
      <el-row :gutter="10" style="display: flex; align-items: center">
        <el-col :span="2">
          <h4>课时名称:</h4>
        </el-col>
        <el-col>
          <span>手法动作之一:太极云手式</span>
        </el-col>
      </el-row>
      <el-row>
        <h4>主视频信息</h4>
        <div class="lord-video">
          <PlayVideo
            :videoStyle="lordVideoStyle"
            :imgStyle="lordImgStyle"
          ></PlayVideo>
        </div>
      </el-row>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-row>
        <h4>分解视频设置</h4>
      </el-row>
      <el-row>
        <span>说明：点击下面的“+”来添加分解视频</span>
      </el-row>
      <el-row style="display: flex; margin-top: 20px">
        <template>
          <el-card
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div style="margin-bottom: 10px">分解视频1</div>
            <div class="split-video">
              <PlayVideo
                :videoStyle="splitVideoStyle"
                :imgStyle="splitImgStyle"
              ></PlayVideo>
            </div>
            <div style="margin: 10px 0">上扬式</div>
            <div>
              <el-button
                type="primary"
                style="width: 150px; margin-bottom: 10px"
                >编辑</el-button
              >
            </div>
            <div>
              <el-button type="danger" style="width: 150px">删除</el-button>
            </div>
          </el-card>
        </template>

        <div class="avatar-uploader">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            @click="dialogVisible = true"
          ></i>
        </div>
      </el-row>
    </el-card>
    <el-dialog
      title="创建分解视频"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="视频名称" prop="videoName">
          <el-input
            v-model="form.videoName"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="视频封面" prop="videoCover">
          <div style="width: 178px; height: 178px">
            <image-cutter></image-cutter>
          </div>
          <div style="color: red">
            请设置长宽相同的封面，推荐使用300*300、400*400、500*500像素的图片
          </div>
        </el-form-item>
        <el-form-item label="上传视频" prop="uploadVideo">
          <el-button>点击上传</el-button>
        </el-form-item>
        <el-form-item label="文字说明">
          <el-input
            type="textarea"
            v-model="form.description"
            maxlength="10000"
            show-word-limit
            rows="10"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" style="width: 200px"
            >完成</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PlayVideo from "@/components/PlayVideo.vue";
import ImageCutter from "@/components/ImageCutter.vue";
export default {
  components: {
    PlayVideo,
    ImageCutter,
  },
  data() {
    return {
      // 主视频样式
      lordVideoStyle: {
        width: "600px",
        height: "300px",
        position: "relative",
      },
      lordImgStyle: {
        width: "100px",
        height: "100px",
      },
      // 分解视频样式
      splitVideoStyle: {
        width: "150px",
        height: "150px",
        position: "relative",
      },
      splitImgStyle: {
        width: "50px",
        height: "50px",
      },
      form: {
        videoName: "",
        videoCover: "",
        uploadVideo: "",
        description: "",
      },
      rules: {
        videoName: [
          { required: true, message: "请输入视频名称", trigger: "blur" },
        ],
        videoCover: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        uploadVideo: [
          { required: true, message: "请上传视频", trigger: "change" },
        ],
      },
      dialogVisible: false,
      fileList: [],
    };
  },
  methods: {
    handleUploadSuccess(response, file, fileList) {
      // Handle upload success
      console.log(response, file, fileList);
    },
    beforeUpload(file) {
      // Before upload logic
      console.log(file);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // Submit form logic
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 50px;
}
.avatar-uploader {
  margin: 20px 0 0 30px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 150px;
  height: 150px;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}
.upload-demo {
  display: inline-block;
  margin-right: 10px;
}
</style>

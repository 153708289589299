import { render, staticRenderFns } from "./setMytab.vue?vue&type=template&id=150e1b91&scoped=true&"
import script from "./setMytab.vue?vue&type=script&lang=js&"
export * from "./setMytab.vue?vue&type=script&lang=js&"
import style0 from "./setMytab.vue?vue&type=style&index=0&id=150e1b91&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150e1b91",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./retrievePassword.vue?vue&type=template&id=84900120&scoped=true&"
import script from "./retrievePassword.vue?vue&type=script&lang=js&"
export * from "./retrievePassword.vue?vue&type=script&lang=js&"
import style0 from "./retrievePassword.vue?vue&type=style&index=0&id=84900120&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84900120",
  null
  
)

export default component.exports
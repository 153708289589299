<template>
  <div style="padding: 20px">
    <el-row>
      <el-button type="primary" @click="back">返回上一页</el-button>
    </el-row>
    <el-row id="demo">
      <el-input
        type="textarea"
        :rows="25"
        placeholder="请输入内容"
        v-model="textarea"
        style="width: 60%; margin: 15px 0 25px"
      >
      </el-input>
    </el-row>
    <el-row style="margin-left: 25%">
      <el-button type="primary" @click="loadPdf">导出pdf</el-button>
    </el-row>
  </div>
</template>

<script>
import { downloadPDF } from "@/utils/pdf.js";
export default {
  data() {
    return { textarea: "" };
  },
  created() {
    this.$route.query;
    console.log(this.$route.query);
    this.textarea = this.$route.query.row.context;
  },
  // created() {
  //   this.$route.query;
  //   console.log(this.$route.query);
  //   this.textarea = this.$route.query.row.context;
  // },
  methods: {
    back() {
      this.$router.back();
    },
    loadPdf() {
      downloadPDF(document.getElementById("demo"));
    },
  },
};
</script>

<style>
.el-textarea__inner {
  line-height: 18.5;
}
</style>

<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="赠送原因" prop="name" required>
        <el-input
          disabled
          v-model="ruleForm.name"
          style="width: 30%"
        ></el-input>
      </el-form-item>
      <el-form-item label="邀请好友人数" prop="appmoneyricep" required>
        <el-input v-model="ruleForm.appmoneyricep" style="width: 26%"></el-input
        >人
      </el-form-item>
      <el-form-item label="赠送脱单币个数" prop="time" required>
        <el-input v-model="ruleForm.time" style="width: 24%"></el-input
        >个,脱单币
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 15%; margin-top: 25px">
      <el-button
        type="primary"
        style="margin-right: 35px"
        @click="MYtabOk('ruleForm')"
        >完成</el-button
      >
      <el-button @click="back">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { GoldUserStatus } from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        name: "邀请好友得脱单币",
        appmoneyricep: "",
        time: "",
      },
      rules: {
        name: [{ required: true, message: "请输入赠送原因", trigger: "blur" }],
        appmoneyricep: [
          { required: true, message: "请输入邀请好友人数", trigger: "blur" },
        ],
        time: [
          { required: true, message: "请输入赠送脱单币个数", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.$route.query;
    console.log(this.$route.query);
    this.ruleForm.name = this.$route.query.row.givemoneyliyou;
    this.ruleForm.appmoneyricep = this.$route.query.row.friendnum;
    this.ruleForm.time = this.$route.query.row.giveappmoney;
  },
  methods: {
    MYtabOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            id: this.$route.query.row.id,
            state: this.$route.query.row.state,
            friendnum: this.ruleForm.appmoneyricep,
            giveappmoney: this.ruleForm.time,
          };
          let res = await GoldUserStatus(param);
          console.log(res);
          if ("ok" == res.data) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$router.back();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>普通用户管理</span>
      </div>
      <div class="text item" style="font-size: 14px">个人信息。</div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-button
      type="primary"
      style="margin: 20px 0 10px 30px"
      @click="fanhuishanye"
      >返回上一页</el-button
    >
    <!-- 内容区 -->
    <div>
      <!-- v-loading="loading" -->
      <h2 style="margin-left: 30px">用户基本信息</h2>
      <table style="margin-left: 30px">
        <thead>
          <tr>
            <th style="width: 120px">序号</th>
            <th style="width: 300px">信息项名称</th>
            <th>用户信息</th>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr>
            <td>1</td>
            <td>昵称</td>
            <td>{{ tableData.name ? tableData.name : "未知" }}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>微信号</td>
            <td>{{ tableData.wecahtnum ? tableData.wecahtnum : "未知" }}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>出生日期</td>
            <td>{{ brithday ? brithday : "未知" }}</td>
          </tr>
          <tr>
            <td>4</td>
            <td>性别</td>
            <td>{{ tableData.sex == "0" ? "男" : "女" }}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>月收入</td>
            <td>{{ money ? money : "0" }}</td>
          </tr>
          <tr>
            <td>6</td>
            <td>电话</td>
            <td>{{ tableData.phonenum ? tableData.phonenum : "未知" }}</td>
          </tr>
          <tr>
            <td>7</td>
            <td>身高</td>
            <td>{{ tableData.stature ? tableData.stature : "0" }}cm</td>
          </tr>
          <tr>
            <td>8</td>
            <td>现居地</td>
            <td>
              {{
                tableData.currentResidence ? tableData.currentResidence : "未知"
              }}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>家乡</td>
            <td>{{ tableData.oldHome ? tableData.oldHome : "未知" }}</td>
          </tr>
          <!-- <tr>
            <td>13</td>
            <td>毕业院校</td>
            <td>{{ tableData.school ? tableData.school : "未知" }}</td>
          </tr> -->
          <tr>
            <td>10</td>
            <td>我的愿望</td>
            <td>
              <span
                class="biaoqian"
                v-for="(item, index) in chineseArr"
                :key="index"
                >{{ item ? item : "无" }}</span
              >
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>个人形象</td>
            <td>
              <span @click="phonetu" class="biaoqian" style="color: #36d"
                >点击查看大图</span
              >
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>自我介绍</td>
            <td>{{ tableData.styletalke ? tableData.styletalke : "未知" }}</td>
          </tr>
          <tr>
            <td>13</td>
            <td>我的标签</td>
            <td>
              <span
                class="biaoqian"
                v-for="(item, index) in userbiaoqian"
                :key="index"
                >{{ item.name ? item.name : "无" }}
              </span>
            </td>
          </tr>
          <tr>
            <td>14</td>
            <td>我理想中的TA</td>
            <td>{{ tableData.likepeople ? tableData.likepeople : "未知" }}</td>
          </tr>
          <tr>
            <td>15</td>
            <td>他的标签</td>
            <td>
              <span
                class="biaoqian"
                v-for="(item, index) in touserbiaoqian"
                :key="index"
                >{{ item.name ? item.name : "无" }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <h2 style="margin-left: 30px">用户身份认证信息</h2>
      <table style="margin-left: 30px">
        <thead>
          <tr>
            <th style="width: 120px">序号</th>
            <th style="width: 300px">信息项名称</th>
            <th>用户信息</th>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr>
            <td>1</td>
            <td>真实姓名</td>
            <td>{{ tableData.relayname ? tableData.relayname : "未知" }}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>身份证号</td>
            <td>{{ tableData.idcard ? tableData.idcard : "未知" }}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>身份证正面</td>
            <td>
              <span
                v-if="tableData.idCardFrontImgUrl"
                @click="showImage(tableData.idCardFrontImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>身份证背面</td>
            <td>
              <span
                v-if="tableData.idCardRearImgUrl"
                @click="showImage(tableData.idCardRearImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>最高学历</td>
            <td>{{ student ? student : "未知" }}</td>
          </tr>
          <tr>
            <td>6</td>
            <td>学校名称</td>
            <td>{{ tableData.school ? tableData.school : "未知" }}</td>
          </tr>
          <tr>
            <td>7</td>
            <td>毕业证照片</td>
            <td>
              <span
                v-if="tableData.diplomaImgUrl"
                @click="showImage(tableData.diplomaImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>职业类型</td>
            <td>
              {{ jobType ? jobType : "未知" }}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>婚恋状况</td>
            <td>{{ myhunyin ? myhunyin : "未知" }}</td>
          </tr>
          <!-- <tr>
            <td>13</td>
            <td>毕业院校</td>
            <td>{{ tableData.school ? tableData.school : "未知" }}</td>
          </tr> -->
          <tr>
            <td>10</td>
            <td>婚恋证件</td>
            <td>
              <span
                v-if="tableData.emotionCredentialsImgUrl"
                @click="showImage(tableData.emotionCredentialsImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>是否有房</td>
            <td>
              {{
                tableData.home || tableData.home == 0
                  ? tableData.home == 0
                    ? "无"
                    : "有"
                  : "未知"
              }}
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>房产证</td>
            <td>
              <span
                v-if="tableData.houseCredentialsImgUrl"
                @click="showImage(tableData.houseCredentialsImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>是否有车</td>
            <td>
              {{
                tableData.car || tableData.car == 0
                  ? tableData.car == 0
                    ? "无"
                    : "有"
                  : "未知"
              }}
            </td>
          </tr>
          <tr>
            <td>14</td>
            <td>车辆行驶证</td>
            <td>
              <span
                v-if="tableData.carCredentialsImgUrl"
                @click="showImage(tableData.carCredentialsImgUrl)"
                class="biaoqian"
                style="color: #36d"
                >点击查看大图</span
              >
              <span v-else>未上传</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <el-dialog title="查看图片" :visible.sync="dialogVisible" width="30%">
        <img
          v-for="(item, index) in phonetupian"
          :key="index"
          :src="item.photoimage"
          alt=""
          style="max-width: 100%; border: 1px solid #000; margin-bottom: 15px"
        />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { messageList, phonetu } from "@/api/userManagement";
export default {
  data() {
    return {
      tableData: "",
      brithday: "",
      money: "",
      loading: true,
      student: "",
      myhunyin: "",
      touserbiaoqian: [],
      userbiaoqian: [],
      chineseArr: [],
      phonetupian: [],
      dialogVisible: false,
      userAuthInfo: {},
      jobType: null,
      emotion: null,
    };
  },
  created() {
    this.messageList();
  },
  destroyed() {},
  methods: {
    // 返回上一页
    fanhuishanye() {
      this.$router.back();
    },
    // 渲染列表数据
    async messageList() {
      console.log(this.$route.query.id);
      this.loading = true;
      let param = {
        id: this.$route.query.id,
      };
      let res = await messageList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.user;
        this.brithday = this.tableData.brithday.slice(0, 10);
        this.touserbiaoqian = res.data.touserbiaoqian;
        this.userbiaoqian = res.data.userbiaoqian;
        this.chineseArr = res.data.mywish.map((item) => {
          if (item === 0) {
            return "交友"; // 可以根据需要替换为适当的汉字
          } else if (item === 1) {
            return "相亲"; // 可以根据需要替换为适当的汉字
          } else if (item === 2) {
            return "娱乐"; // 可以根据需要替换为适当的汉字
          } else {
            return item;
          }
        });
        console.log(this.chineseArr);
        switch (this.tableData.myhunyin) {
          case 0:
            this.myhunyin = "已婚";
            break;
          case 1:
            this.myhunyin = "未婚";
            break;
          case 2:
            this.myhunyin = "离异";
            break;
          case 3:
            this.myhunyin = "丧偶";
            break;
        }
        switch (this.tableData.student) {
          case 0:
            this.student = "小学";
            break;
          case 1:
            this.student = "初中";
            break;
          case 2:
            this.student = "中专";
            break;
          case 3:
            this.student = "高中";
            break;
          case 4:
            this.student = "专科";
            break;
          case 5:
            this.student = "本科";
            break;
          case 6:
            this.student = "硕士研究生";
            break;
          case 7:
            this.student = "博士研究生";
            break;
        }
        switch (this.tableData.jobType) {
          case 0:
            this.jobType = "国家机关、党群组织、企业事业单位负责人";
            break;
          case 1:
            this.jobType = "专业技术人员";
            break;
          case 2:
            this.jobType = "办事人员和有关人员";
            break;
          case 3:
            this.jobType = "商业服务业人员";
            break;
          case 4:
            this.jobType = "农林牧渔、水利业生产人员";
            break;
          case 5:
            this.jobType = "生产运输设备操作人员及有关人员";
            break;
          case 6:
            this.jobType = "军人";
            break;
          case 7:
            this.jobType = "特殊职业的其他从业人员";
            break;
        }
        switch (this.tableData.money) {
          case 0:
            this.money = "3000元以下";
            break;
          case 1:
            this.money = "3000-5000元";
            break;
          case 2:
            this.money = "5001-8000元";
            break;
          case 3:
            this.money = "8001-10000元";
            break;
          case 4:
            this.money = "10001-20000元";
            break;
          case 5:
            this.money = "20001-50000元";
            break;
          case 6:
            this.money = "50000元以上";
            break;
        }
      } else {
        this.$message({
          type: "error",
          message: "操作失败",
        });
      }
    },
    // 查看图片
    async phonetu() {
      this.dialogVisible = true;
      let param = {
        id: this.$route.query.id,
      };
      let res = await phonetu(param);
      console.log(res, "4545");
      this.phonetupian = res.data;
      console.log(this.phonetupian);
    },
    showImage(url) {
      this.dialogVisible = true;
      this.phonetupian = [];
      this.phonetupian.push({
        photoimage: url,
      });
    },
  },
};
</script>
<style scoped>
table {
  width: 60%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

thead {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}
.biaoqian {
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: 20px;
  padding: 2px 4px;
  background: #f2f2f2;
  margin-right: 10px;
}
</style>

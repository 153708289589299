<template>
  <div id="catalogueContent">
    <el-card class="box-card">
      <div class="clearfix">
        <el-row :gutter="50">
          <el-col :span="10">
            <span>课程名称:</span>
            <span class="ml-20">代号kc-003：自由创造-柔力球系统化学习</span>
          </el-col>
          <el-col :span="10">
            <span>收费模式:</span>
            <span class="ml-20">连载收费模式</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <div id="sectionAndPeriod">
      <div class="sectionCatalogue">
        <el-card class="card">
          <div slot="header">
            <span>章节列表</span>
          </div>
          <div class="text item">
            <div class="sectionSearch">
              <el-input
                v-model="sectionValue"
                placeholder="请输入章节名称关键字"
                clearable
              ></el-input>
              <el-button type="primary" @click="sectionSearch">查询</el-button>
            </div>
            <el-scrollbar style="height: 500px">
              <template v-for="item in sectionList">
                <div class="sectionItem" :key="item">
                  <div>自创动作汇总</div>
                  <div>已有课时：{{ item }}</div>
                </div>
              </template>
            </el-scrollbar>
          </div>
          <el-button
            style="width: 100%; margin-top: 20px"
            @click="navigationSectionList"
            >设置章节目录</el-button
          >
        </el-card>
      </div>
      <div class="period">
        <template v-if="sectionList">
          <periodListVue :chargeMode="chargeMode"></periodListVue>
        </template>
        <template v-else>
          <div style="margin-top: 100px; text-align: center">
            您需要先设置“章节”才可创建课程内容，请点击左边“章节目录”下面的“设置章节目录”按钮来进行操作。
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import periodListVue from "./periodList.vue";
export default {
  components: {
    periodListVue,
  },
  data() {
    return {
      sectionValue: "",
      sectionList: 20,
      chargeMode: "0", // 收费模式
    };
  },
  methods: {
    // 搜索
    sectionSearch() {},
    onLoad() {},
    // 跳转章节目录
    navigationSectionList() {
      this.$router.push({ path: "/sectionList" });
    },
  },
};
</script>

<style scoped>
#sectionAndPeriod {
  margin-top: 20px;
  display: flex;
}
.sectionCatalogue .card {
  width: 300px;
  height: 100%;
  max-height: 800px;
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.sectionSearch {
  display: flex;
  margin-bottom: 10px;
}
.sectionItem {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #eee;
}
.sectionItem:hover {
  background-color: #ccc;
}
.period {
  width: 80%;
}
</style>

<template>
  <ImgCutter @cutDown="cutDown">
    <template #open>
      <div class="avatar-uploader">
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </div>
    </template>
  </ImgCutter>
</template>

<script>
import ImgCutter from "vue-img-cutter";
export default {
  components: {
    ImgCutter,
  },
  props: {
    imageUrl: String,
  },
  methods: {
    //通过cutDown事件接收选择的图片信息
    cutDown(event) {
      console.log(event);
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  width: 178px;
  height: 178px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 10px;
}
</style>

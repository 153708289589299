<template>
  <div>
    <div class="form-container">
      <h2>一、课时基本信息</h2>
      <el-form
        ref="periodForm"
        :rules="periodFormRules"
        :model="periodForm"
        label-width="120px"
      >
        <el-form-item label="课时名称" prop="name">
          <el-input
            placeholder="请输入课时名称"
            v-model="periodForm.name"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h2>二、课时类型设置</h2>
      <el-form
        ref="periodTypeForm"
        :rules="periodTypeFormRules"
        :model="periodTypeForm"
        label-width="120px"
      >
        <el-form-item label="选择课时类型" prop="type">
          <br />
          <el-radio-group v-model="periodTypeForm.type">
            <div>
              <el-radio label="imageText">图文</el-radio>
              <div
                style="
                  font-size: 14px;
                  color: #999999;
                  margin: 20px 0 20px 20px;
                "
              >
                说明：该课时的内容为图文内容，可以设置图片、文章、小视频的展现内容。
              </div>
            </div>
            <div style="position: relative">
              <el-radio label="video">视频</el-radio>
              <div
                style="
                  font-size: 14px;
                  color: #999999;
                  margin: 20px 0 20px 20px;
                "
              >
                说明：该课时的内容为视频，可支持教学视频和分解视频。
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="设置课时内容" prop="content">
          <br />
          <template v-if="!periodTypeForm.type">
            <div>请先选择课时类型。</div>
          </template>
          <template v-if="periodTypeForm.type == 'imageText'">
            <WangEditor></WangEditor>
          </template>
          <template v-if="periodTypeForm.type == 'video'">
            <div>
              <el-row>
                <el-col :span="4">
                  <span style="color: #f56c6c; margin-right: 4px">*</span
                  ><span>视频标签</span>
                </el-col>
                <el-col :span="6">
                  <el-checkbox>添加“精讲视频”标签</el-checkbox>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">&nbsp;</el-col>
                <el-col :span="20" style="font-size: 14px; color: #999999">
                  说明：这是一个标签，一般您可以给有分解视频的课时设置“精讲视频”的标签
                </el-col>
              </el-row>
            </div>
            <el-card class="box-card">
              <div slot="header" class="clearfix">主视频设置</div>
              <div class="set-video">
                <div>
                  说明：主视频必须设置，教育应用上，主视频要能够说明整体教学内容。
                </div>
                <el-row class="image-and-video">
                  <el-col :span="4">
                    <span style="color: #f56c6c; margin-right: 4px">*</span
                    ><span>主视频封面图</span>
                  </el-col>
                  <el-col :span="8">
                    <image-cutter></image-cutter>
                    <span class="red">请设置640*340像素的图片</span>
                  </el-col>
                  <el-col :span="4">
                    <div>
                      <span style="color: #f56c6c; margin-right: 4px">*</span
                      ><span>主视频封面图</span>
                    </div>
                    <el-button type="primary">点击上传</el-button>
                  </el-col>
                </el-row>
                <div></div>
              </div>
              <div class="video-introduce">
                <el-row>
                  <el-col :span="2">介绍</el-col>
                  <el-col :span="22">
                    <WangEditor></WangEditor>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </template>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h2>三、上架设置</h2>
      <el-form ref="statusForm" :model="statusForm" label-width="120px">
        <el-form-item label="">
          <el-radio-group v-model="statusForm.published">
            <el-radio label="true">上架</el-radio>
            <el-radio label="false">下架</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>

      <template>
        <h2>四、收费设置</h2>
        <el-form ref="chargeForm" :model="chargeForm" label-width="120px">
          <el-form-item label="">
            <div class="red">
              说明：该课时的课程的收费模式为连载收费模式，所以需要您设置本课时的收费金额。
            </div>
            <span>收费金额：</span>
            <el-input
              type="Number"
              min="0"
              v-model="chargeForm.money"
              style="width: 150px"
            ></el-input>
            <span>元（人民币）</span>
          </el-form-item>
        </el-form>
      </template>
      <div class="button-container">
        <el-button type="primary" @click="submitForm">完成创建</el-button>
        <el-button @click="goToSettings"
          >完成创建后，直接去设置目录和内容</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEditor.vue";
import ImageCutter from "@/components/ImageCutter.vue";
export default {
  components: { WangEditor, ImageCutter },
  data() {
    return {
      periodForm: {
        name: "",
        summary: "",
        // description: "",
        cover: [],
        category: "",
        imageUrl:
          "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
      },
      chargeForm: {
        mode: "",
        money: "",
      },
      statusForm: {
        published: "true",
      },
      periodTypeForm: {
        type: "",
        content: "",
      },
      periodFormRules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        summary: [
          { required: true, message: "请输入课程摘要", trigger: "blur" },
        ],
        description: [
          {
            required: true,
            message: "请输入课程介绍",
            trigger: "blur",
          },
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传课程封面",
            trigger: "change",
          },
        ],
        category: [
          { required: true, message: "请选择课程类别", trigger: "change" },
        ],
      },
      periodTypeFormRules: {
        type: [{ required: true, message: "请选择课时类型", trigger: "blur" }],
        content: [
          { required: true, message: "请设置课时内容", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    description() {
      return this.$store.editorContent;
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    submitForm() {
      // Submit form logic
      this.$router.go(-1);
    },
    goToSettings() {
      // Go to settings logic
    },
    cancel() {
      // Cancel logic
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.form-container {
  margin: 0 100px;
  max-width: 1000px;
}

.button-container {
  margin-top: 20px;
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.image-and-video {
  margin-top: 20px;
}
.red {
  color: red;
}
</style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>个人动态服务协议管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对个人动态服务协议进行增删改查。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>

      <!-- <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-col> -->
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="FWalldeleteUser"
      >批量删除</el-button
    >
    <el-button
      style="background: rgb(154, 104, 255); color: #fff; margin-bottom: 10px"
      @click="newQing"
      >新增项目</el-button
    >
    <el-table
      style="width: 80%"
      :data="tableData"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        label="用户协议名"
        prop="name"
        width="120"
        align="center"
      />
      <el-table-column
        prop="indate"
        label="创建时间"
        width="200"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-dialog
            title="给用户发消息"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
          >
            <p>接收人：{{ sendMsgData.wechatName }}</p>
            <el-form>
              <el-form-item label="内容">
                <el-input type="textarea" v-model="messageContent"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="sendMessage">确 定</el-button>
            </div>
          </el-dialog>
          <el-button type="primary" @click="viewQing(scope.row)"
            >查看详情</el-button
          >
          <el-button type="primary" @click="editQing(scope.row)"
            >修改信息</el-button
          >
          <el-button type="danger" @click="FWdeleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  FWgetUserList,
  FWUserStatus,
  FWdeleteUser,
  FWalldeleteUser,
  // setAdminOrBindAdminUser,
} from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      state: 1,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
      dialogVisible: false, // 发消息弹出框
      sendMsgData: "", // 发送消息对象的信息
      messageContent: "", // 发送消息的内容
    };
  },
  created() {
    this.FWgetUserList();
  },
  methods: {
    // 新增
    newQing() {
      this.$router.push({ path: "/newQing" });
    },
    // 查看详情
    viewQing(row) {
      console.log(row, "详情");
      this.$router.push({ path: "/viewQing", query: { row: row } });
    },
    // 修改详情
    editQing(row) {
      console.log(row, "修改");
      this.$router.push({ path: "/editQing", query: { row: row } });
    },
    study() {
      this.$router.push("/checkStudyData");
    },
    // 获取普通用户列表
    async FWgetUserList() {
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态	true禁用	false禁用
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await FWgetUserList(param);
      console.log(res);
      if (res.status == "200") {
        res.data.list.forEach((item, index) => {
          console.log(item, item.indate);
          res.data.list[index].indate = item.indate.slice(0, 10);
        });
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.FWgetUserList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.FWgetUserList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.FWgetUserList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await FWUserStatus({ id: row.id, state: row.state });
      if (res.data == "修改成功") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.FWgetUserList();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.sendMsgData = row;

      this.dialogVisible = true;
    },
    async FWdeleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await FWdeleteUser({
          id: row.id,
        });
        console.log("deleteUser", res);
        if (res.data == "成功") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.FWgetUserList();
        }
      });
    },
    // 批量删除
    async FWalldeleteUser(row) {
      console.log("deleteUser", row);
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将删除这些数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await FWalldeleteUser({
          id: ids,
        });
        console.log("deleteUser", res);
        if (res.data == "成功") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.FWgetUserList();
        }
      });
    },
    // 设为管理员
    async setAdmin(row) {
      console.log("setAdmin", row);
      // let res = await setAdminOrBindAdminUser({
      //   id: row.id,
      //   isAdmin: 1,
      // });
      // if (res.data.result == "success") {
      //   this.$message({
      //     type: "success",
      //     message: res.data.msg,
      //   });
      //   this.getUserList();
      // }
      // console.log("setAdmin", res);
    },
    addUser() {
      console.log("addUser");
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

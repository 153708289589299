<template>
  <div>
    <div style="margin: 20px">
      <el-button type="primary" @click="back">返回上一页</el-button>
    </div>
    <div style="margin-left: 20px">
      <el-table
        :data="tableData"
        style="width: 90%"
        @selection-change="handleSelectionChange"
        border
        v-loading="loading"
        :header-cell-style="{ background: '#f2f2f2' }"
      >
        <el-table-column label="序号" type="index" width="80" align="center" />
        <el-table-column
          label="uni-id"
          prop="userid"
          width="120"
          align="center"
        />

        <el-table-column
          prop="username"
          label="微信名"
          width="200"
          align="center"
        />
        <el-table-column
          prop="pakegname"
          label="脱单币套餐"
          width="200"
          align="center"
        />
        <el-table-column
          prop="appmoneynum"
          label="购买数量(个)"
          width="200"
          align="center"
        />
        <el-table-column
          prop="money"
          label="实付金额(元)"
          width="200"
          align="center"
        />
        <el-table-column
          prop="ttime"
          label="购买时间"
          width="200"
          align="center"
        />
        <el-table-column prop="beizhu" label="备注" align="center" />
      </el-table>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
      />
    </div>
  </div>
</template>

<script>
import { currencySingle } from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  created() {
    this.currencySingle();
  },
  methods: {
    async currencySingle() {
      this.loading = true;
      let param = {
        page: this.currentPage,
        pagesize: this.pageSize,
      };
      let res = await currencySingle(param);
      console.log(res);
      this.loading = false;
      if ("200" == res.status) {
        this.tableData = res.data.list;
        this.total = res.data.totle;
      }
    },
    handleSelectionChange(selectedRows) {
      console.log(selectedRows);
      this.selectedRows = selectedRows;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currencySingle();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.currencySingle();
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>动态管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对用户发布的动态进行查看和删除。
      </div>
    </el-card>
    <el-row style="margin: 20px 0 0 20px">
      <el-col :span="2"> 设置动态权限：</el-col>
      <el-col :span="3">
        <el-switch
          v-model="value1"
          @change="change1"
          inactive-text="发布动态功能设置"
        >
        </el-switch>
      </el-col>
      <el-col :span="3">
        <el-switch
          v-model="value2"
          @change="change2"
          inactive-text="评论功能设置"
        >
        </el-switch>
      </el-col>
      <el-col :span="3">
        <el-switch
          v-model="value3"
          @change="change3"
          inactive-text="隐藏所有动态设置"
        >
        </el-switch>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入动态关键词"
          @keyup.enter.native="search"
        />
      </el-col>
      <!-- <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col> -->
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="AllearDelete"
      >批量删除</el-button
    >
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 70%"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#f2f2f2' }"
      border
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        label="昵称"
        prop="nikename"
        width="200"
        align="center"
      />
      <el-table-column
        prop="dadatetime"
        label="发布日期"
        width="200"
        align="center"
      />
      <el-table-column
        prop="alikeFriendsbook.context"
        label="动态"
        align="center"
      />
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="info" @click="ckanetAdmin(scope.row)"
            >查看动态</el-button
          >
          <el-button
            style="background-color: red; color: #fff"
            @click="earDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  DTgetUserList,
  earDelete,
  AllearDelete,
  changeFbu,
  changePlun,
  changeAll,
  changeFbu1,
  changePlun1,
  changeAll1,
} from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      checkList: [],
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: false,
      ids: [],
      selectedRows: [],
      value1: true,
      value2: true,
      value3: true,
    };
  },
  created() {
    this.DTgetUserList();
    this.changeFbu();
    this.changePlun();
    this.changeAll();
  },
  methods: {
    // 动态设置
    async changeFbu() {
      console.log("5555");
      let res = await changeFbu();
      console.log(res);
      if ("200" == res.status) {
        this.value1 = res.data;
      }
    },
    async change1(e) {
      console.log(e, "5555");
      let param = {
        state: e,
      };
      let res = await changeFbu1(param);
      console.log(res);
      if ("ok" == res.data) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    async changePlun() {
      console.log("5555");
      let res = await changePlun();
      console.log(res);
      if ("200" == res.status) {
        this.value2 = res.data;
      }
    },
    async change2(e) {
      console.log(e, "5555");
      let param = {
        state: e,
      };
      let res = await changePlun1(param);
      console.log(res);
      if ("ok" == res.data) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    async changeAll() {
      console.log("5555");
      let res = await changeAll();
      console.log(res);
      if ("200" == res.status) {
        this.value3 = res.data;
      }
    },
    async change3(e) {
      console.log(e, "5555");
      let param = {
        state: e,
      };
      let res = await changeAll1(param);
      console.log(res);
      if ("ok" == res.data) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    studCkan() {
      this.$router.push("/checkStudyData");
    },
    // 获取普通用户列表
    async DTgetUserList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        // status: this.selectedUnit, //状态	true禁用	false禁用
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await DTgetUserList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.DTgetUserList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.DTgetUserList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.DTgetUserList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    // 查看动态详情
    ckanetAdmin(row) {
      console.log(row, "9999");
      this.$router.push({
        path: "/ckanetAdmin",
        query: { id: row.alikeFriendsbook.id },
      });
    },
    // 删除功能
    async earDelete(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await earDelete({
          id: row.alikeFriendsbook.id,
        });
        console.log("deleteUser", res);
        if (res.data == "删除成功") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.DTgetUserList();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    //批量删除
    async AllearDelete() {
      var id = this.selectedRows.map((item) => {
        return item.alikeFriendsbook.id;
      });
      console.log(id);
      this.$confirm("此操作将删除这些用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let param = {
          id: id,
        };
        let res = await AllearDelete(param);
        console.log(res);
        if (res.data == "删除成功") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.DTgetUserList();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

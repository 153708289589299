<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>咨询费设置</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对咨询费设置进行设置。
      </div>
    </el-card>
    <el-form
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="咨询名称" prop="beizhu" required>
        <el-input v-model="ruleForm.beizhu" style="width: 30%"></el-input
        ><span style="font-size: 12px; color: #ccc"></span>
      </el-form-item>
      <el-form-item label="咨询价格" prop="price" required>
        <el-input v-model="ruleForm.price" style="width: 30%"></el-input
        >个,脱单币
      </el-form-item>
      <el-form-item label="预约次数" prop="cishu" required>
        <el-input
          disabled
          v-model="ruleForm.cishu"
          style="width: 30%"
        ></el-input
        >次
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 20%; margin-top: 25px">
      <el-button
        type="primary"
        style="margin-right: 35px"
        @click="MYtabOk('ruleForm')"
        >完成</el-button
      >
      <!-- <el-button @click="back">取消</el-button> -->
    </el-row>
  </div>
</template>

<script>
import { courQuestionList, Double } from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      tableData: "",
      ruleForm: {
        beizhu: "",
        price: "",
        cishu: "1",
      },
      rules: {
        beizhu: [
          { required: true, message: "请输入咨询名称", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入咨询价格", trigger: "blur" }],
        cishu: [{ required: true, message: "请输入次数", trigger: "blur" }],
      },
    };
  },
  created() {
    this.courQuestionList();
  },
  methods: {
    async courQuestionList() {
      this.loading = true;
      let res = await courQuestionList();
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.ruleForm.beizhu = res.data.beizhu;
        this.ruleForm.price = res.data.price;
      }
      console.log(this.tableData, "res");
    },
    MYtabOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            appmoney: this.ruleForm.price,
            tuijian: this.ruleForm.beizhu,
          };
          let res = await Double(param);
          console.log(res);
          if ("ok" == res.data) {
            this.$message({
              type: "success",
              message: "设置成功",
            });
            this.courQuestionList();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // back() {
    //   this.$router.back();
    // },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>会员设置</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对会员权限进行参数设置。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>

    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 60%; margin: 30px"
      :header-cell-style="{ background: '#f2f2f2' }"
      border
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column label="序号" type="index" width="100" align="center" />
      <el-table-column label="会员套餐权限" prop="name" align="center" />
    </el-table>
  </div>
</template>

<script>
import { setMemberList } from "@/api/userManagement";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.setMemberList();
  },
  methods: {
    // 获取普通用户列表
    async setMemberList() {
      let res = await setMemberList();
      console.log(res);
      if (res.status == "200") {
        this.tableData = res.data;
      }
    },
    handleSelectionChange(selectedRows) {
      console.log(selectedRows);
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

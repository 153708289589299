<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>脱单币套餐设置</span>
      </div>
      <div class="text item" style="font-size: 14px">脱单币套餐设置。</div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>

      <!-- <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-col> -->
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="CanceAlldeleteUser"
      >批量删除</el-button
    >
    <el-button
      style="background: rgb(154, 104, 255); color: #fff; margin-bottom: 10px"
      @click="newMation"
      >新增项目</el-button
    >
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 80%"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#f2f2f2' }"
      border
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        label="会员套餐"
        prop="name"
        width="120"
        align="center"
      />
      <el-table-column
        label="金额(元)"
        prop="money"
        width="120"
        align="center"
      /><el-table-column
        label="购买脱单币(个)"
        prop="appmoneynum"
        width="120"
        align="center"
      />
      <el-table-column
        prop="fangpi"
        label="套餐详情(换算后)"
        width="250"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="info" @click="setAdmin(scope.row)"
            >修改信息</el-button
          >
          <el-button
            style="background-color: red; color: #fff"
            @click="deleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  setCancelled,
  CanceUserStatus,
  CanceSetdeleteUser,
  CanceAlldeleteUser,
} from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      state: 1,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
    };
  },
  created() {
    this.setCancelled();
  },
  methods: {
    study() {
      this.$router.push("/checkStudyData");
    },
    // 获取普通用户列表
    async setCancelled() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态
        pageNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      };
      let res = await setCancelled(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.totle;
      }
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.setCancelled();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.setCancelled();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.setCancelled();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await CanceUserStatus({ id: row.id, state: row.state });
      console.log(res);
      if (res.data == "ok") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.setCancelled();
      }
      console.log(res, "changeStatus");
    },

    async deleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await CanceSetdeleteUser({
          id: row.id,
        });
        console.log(res);
        if (res.data == "ok") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.setCancelled();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    async CanceAlldeleteUser(row) {
      console.log("deleteUser", row);
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将删除这些数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await CanceAlldeleteUser({
          id: ids,
        });
        console.log("deleteUser", res);
        if (res.data == "ok") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.setCancelled();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    // 修改信息
    setAdmin(row) {
      console.log("setAdmin", row);
      this.$router.push({ path: "/setCancell", query: { row: row } });
    },
    // 新增
    newMation() {
      this.$router.push({ path: "/newCancell" });
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

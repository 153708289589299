<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="会员套餐名称" prop="name" required>
        <el-input v-model="ruleForm.name" style="width: 30%"></el-input
        ><span style="font-size: 14px; color: #ccc">(格式:套餐一)</span>
      </el-form-item>
      <el-form-item label="会员套餐价格" prop="appmoneyricep" required>
        <el-input v-model="ruleForm.appmoneyricep" style="width: 30%"></el-input
        >个,脱单币
      </el-form-item>
      <el-form-item label="会员天数" prop="time" required>
        <el-input
          v-model="ruleForm.time"
          style="width: 30%; margin-left: 28px"
        ></el-input
        >月
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 15%; margin-top: 25px">
      <el-button
        type="primary"
        style="margin-right: 35px"
        @click="MYtabOk('ruleForm')"
        >完成</el-button
      >
      <el-button @click="back">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { PLfoList, PLtabOk } from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        appmoneyricep: "",
        time: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入会员套餐名称", trigger: "blur" },
        ],
        appmoneyricep: [
          { required: true, message: "请输入会员套餐价格", trigger: "blur" },
          {
            pattern: "^[1-9]([0-9])*$",
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        time: [
          { required: true, message: "请输入会员天数", trigger: "blur" },
          {
            pattern: "^[1-9]([0-9])*$",
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.PLfoList();
    this.$route.query;
    console.log(this.$route.query.row.id);
    // this.ruleForm.name = this.$route.query.row.name;
    // this.ruleForm.appmoneyricep = this.$route.query.row.groupby;
    // this.ruleForm.time = this.$route.query.row.groupby;
  },
  methods: {
    async PLfoList() {
      let param = {
        id: this.$route.query.row.id,
      };
      let res = await PLfoList(param);
      console.log(res);
      if ("200" == res.status) {
        this.ruleForm.name = res.data.packegname;
        this.ruleForm.appmoneyricep = res.data.appmoneyricep;
        this.ruleForm.time = res.data.time;
      }
    },
    MYtabOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            id: this.$route.query.row.id,
            time: this.ruleForm.time,
            appmoney: this.ruleForm.appmoneyricep,
            name: this.ruleForm.name,
          };
          let res = await PLtabOk(param);
          console.log(res);
          if ("成功" == res.data) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$router.back();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 新建课程</span>
      </div>
      <div class="text item" style="font-size: 14px">
        <div>说明：</div>
        <div>
          1、这里进行课程的设置，包括课程的信息、课程的收费方式等的设置，其中课程的收费方式在课程创建后是不可进行修改的。
        </div>
        <div>
          2、关于课程的“目录与内容”是在“课程管理”的列表中的“设置目录与内容”中进行设置的，请您创建课程后到制定位置进行内容的设置。
        </div>
      </div>
    </el-card>
    <div class="form-container">
      <h2>一、课程基础信息</h2>
      <el-form
        ref="courseForm"
        :rules="courseFormRules"
        :model="courseForm"
        label-width="120px"
      >
        <el-form-item label="课程名称" prop="name">
          <el-input
            placeholder="请输入课程名称"
            v-model="courseForm.name"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="课程摘要" prop="summary">
          <el-input
            type="textarea"
            placeholder="请输入课程摘要"
            v-model="courseForm.summary"
            maxlength="120"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="课程介绍" prop="description">
          <WangEditor></WangEditor>
        </el-form-item>
        <el-form-item label="课程封面" prop="imageUrl">
          <ImgCutter></ImgCutter>
          <div style="color: red">请设置640*340像素的图片</div>
        </el-form-item>
        <el-form-item label="课程所属类别" prop="category">
          <el-select v-model="courseForm.category" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="类别1" value="1"></el-option>
            <el-option label="类别2" value="2"></el-option>
            <el-option label="类别3" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h2>二、课程收费模式设置</h2>
      <el-form ref="chargeForm" :model="chargeForm" label-width="120px">
        <el-form-item label="选择收费模式">
          <br />
          <el-radio-group v-model="chargeForm.mode">
            <div>
              <el-radio label="free">全免费模式</el-radio>
              <div
                style="
                  font-size: 14px;
                  color: #999999;
                  margin: 20px 0 20px 20px;
                "
              >
                说明:该课程所有的内容都可以免费学习
              </div>
            </div>
            <div>
              <el-radio label="one-time">一次性收费模式</el-radio>
              <span
                v-if="chargeForm.mode == 'one-time'"
                style="font-size: 14px; margin: 0 10px 0 20px"
                >收费金额:</span
              >
              <el-input
                v-if="chargeForm.mode == 'one-time'"
                style="width: 100px"
                type="Number"
                v-model="chargeForm.money"
              ></el-input>
              <div
                style="
                  font-size: 14px;
                  color: #999999;
                  margin: 20px 0 30px 20px;
                "
              >
                说明：学习收费为一次性收费。一旦设置后，无论课程会有多少更新，收费金额都不会变化。
              </div>
            </div>
            <div style="position: relative">
              <div
                style="
                  color: green;
                  font-size: 14px;
                  position: absolute;
                  left: -50px;
                "
              >
                推荐
              </div>
              <el-radio label="serial">连载收费模式</el-radio>
              <div
                style="
                  font-size: 14px;
                  color: #999999;
                  margin: 20px 0 20px 20px;
                "
              >
                说明：按照课程的每一课时进行收费，每一个课时独立设置收费金额。适合长期连载的课程。
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h2>三、上架设置</h2>
      <el-form ref="statusForm" :model="statusForm" label-width="120px">
        <el-form-item label="">
          <el-radio-group v-model="statusForm.published">
            <el-radio label="true">上架</el-radio>
            <el-radio label="false">下架</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="button-container">
        <el-button type="primary" @click="submitForm">完成创建</el-button>
        <el-button @click="goToSettings"
          >完成创建后，直接去设置目录和内容</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEditor.vue";
import ImgCutter from "@/components/ImageCutter.vue";
export default {
  components: { WangEditor, ImgCutter },
  data() {
    return {
      courseForm: {
        name: "",
        summary: "",
        // description: "",
        cover: [],
        category: "",
        imageUrl:
          "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
      },
      chargeForm: {
        mode: "",
        money: 0,
      },
      statusForm: {
        published: "true",
      },
      courseFormRules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        summary: [
          { required: true, message: "请输入课程摘要", trigger: "blur" },
        ],
        description: [
          {
            required: true,
            message: "请输入课程介绍",
            trigger: "blur",
          },
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传课程封面",
            trigger: "change",
          },
        ],
        category: [
          { required: true, message: "请选择课程类别", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    description() {
      return this.$store.editorContent;
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    submitForm() {
      // Submit form logic
      this.$router.go(-1);
    },
    goToSettings() {
      // Go to settings logic
    },
    cancel() {
      // Cancel logic
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.form-container {
  margin: 0 100px;
  max-width: 1000px;
}

.button-container {
  margin-top: 20px;
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>心仪对象标签设置</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对心仪对象标签进行增删改查。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>

      <!-- <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-col> -->
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="MYallDelete"
      >批量删除</el-button
    >
    <el-button
      style="background: rgb(154, 104, 255); color: #fff; margin-bottom: 10px"
      @click="newtable"
      >新增项目</el-button
    >
    <el-table
      :data="tableData"
      style="width: 80%"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        prop="groupby"
        label="标签分类"
        width="200"
        align="center"
      />
      <el-table-column
        prop="name"
        label="标签名称"
        width="200"
        align="center"
      />
      <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-dialog
            title="给用户发消息"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
          >
            <p>接收人：{{ sendMsgData.wechatName }}</p>
            <el-form>
              <el-form-item label="内容">
                <el-input type="textarea" v-model="messageContent"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="sendMessage">确 定</el-button>
            </div>
          </el-dialog>
          <el-button
            style="background-color: rgb(154, 104, 255); color: #fff"
            @click="setAdmin(scope.row)"
            >修改信息项</el-button
          >
          <el-button type="info" @click="MYtabDeleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  tabgetList,
  MYUserStatus, //启用禁用
  MYtabDeleteUser,
  MYallDelete,
} from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: 1,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
      dialogVisible: false, // 发消息弹出框
      sendMsgData: "", // 发送消息对象的信息
      messageContent: "", // 发送消息的内容
    };
  },
  created() {
    this.tabgetList();
  },
  methods: {
    // 批量删除
    async MYallDelete() {
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将删除这些标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let param = {
          id: ids,
        };
        let res = await MYallDelete(param);
        console.log(res);
        if ("ok" == res.data) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.tabgetList();
        }
      });
    },
    // 新增项目
    newtable() {
      console.log("666");
      this.$router.push({ path: "/newTable" });
    },
    // 获取普通用户列表
    async tabgetList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态	true禁用	false禁用
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await tabgetList(param);
      console.log(res, "666");
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.tabgetList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.tabgetList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await MYUserStatus({ id: row.id, state: row.recovery });
      if (res.data == "ok") {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.tabgetList();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.sendMsgData = row;

      this.dialogVisible = true;
    },
    async MYtabDeleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await MYtabDeleteUser({
            id: row.id,
          });
          console.log("deleteUser", res);
          if (res.data == "ok") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.tabgetList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 修改信息项
    async setAdmin(row) {
      console.log("setAdmin", row);
      this.$router.push({ path: "/editMessage", query: { row: row } });
    },
    addUser() {
      console.log("addUser");
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>信息审核</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对用户填写的信息进行审核。
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <!-- <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col> -->
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="SHsetAdmin"
      >批量删除</el-button
    >

    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 80%"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        label="uni-id"
        prop="userid"
        width="120"
        align="center"
      />

      <el-table-column prop="name" label="昵称" width="200" align="center" />
      <el-table-column
        prop="isvip"
        label="是否为普通用户"
        width="200"
        align="center"
        ><template slot-scope="scope">
          <span>{{ scope.row.isvip === "1" ? "VIP" : "普通" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="phonenum"
        label="手机号"
        width="200"
        align="center"
      />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="SHview(scope.row)">查看信息</el-button>
          <el-button type="primary" @click="SHpass(scope.row)"
            >审核通过</el-button
          >
          <el-button
            style="background-color: red; color: #fff"
            @click="SHnopass(scope.row)"
            >审核不通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  SHgetUserList,
  SHnopass,
  SHpass,
  SHsetAdmin,
} from "@/api/userManagement";
export default {
  data() {
    return {
      loading: false,
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 0,
      // status: [
      //   {
      //     label: "全部",
      //     value: "",
      //   },
      //   {
      //     label: "普通用户",
      //     value: "0",
      //   },
      //   {
      //     label: "VIP用户",
      //     value: "1",
      //   },
      // ],
      selectedRows: [],
    };
  },
  created() {
    this.SHgetUserList();
  },
  methods: {
    // 批量删除
    async SHsetAdmin() {
      var ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.$confirm("此操作将删除这些用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let param = {
          id: ids,
        };
        let res = await SHsetAdmin(param);
        console.log(res);
        if ("操作成功" == res.data) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.SHgetUserList();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    // 获取普通用户列表
    async SHgetUserList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        page: this.currentPage, //起始页码
        pagesize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await SHgetUserList(param);
      console.log(res);
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        this.total = res.data.totle;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.SHgetUserList();
    },
    // 重置sendMessage
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.SHgetUserList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.SHgetUserList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },

    // 查看信息
    SHview(row) {
      console.log(row);
      this.$router.push({ path: "/shenPersonal", query: { id: row.id } });
    },
    // 审核不通过
    async SHnopass(row) {
      console.log("deleteUser", row);
      let res = await SHnopass({
        id: row.id,
      });
      console.log(res);
      if (res.data == "操作成功") {
        this.$message({
          type: "success",
          message: res.data,
        });
        this.SHgetUserList();
      }
    },
    // 审核通过
    async SHpass(row) {
      console.log("setAdmin", row);
      let res = await SHpass({
        userid: row.userid,
      });
      console.log(res);
      if (res.data.msg == "审核成功") {
        this.$message({
          type: "success",
          message: "已通过",
        });
        this.SHgetUserList();
      } else {
        this.$message.error("审核失败");
      }
      console.log("setAdmin", res);
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>

<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="标签分类" prop="sort" required>
        <!-- <el-input v-model="ruleForm.sort" style="width: 30%"></el-input> -->
        <el-select v-model="ruleForm.sort" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签名称" prop="name" required>
        <el-input v-model="ruleForm.name" style="width: 30%"></el-input>
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 15%; margin-top: 25px">
      <el-button type="primary" style="margin-right: 35px" @click="MYtabList"
        >完成</el-button
      >
      <el-button @click="back">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { MYtabList } from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        sort: "",
      },
      rules: {
        name: [{ required: true, message: "请输入标签名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入标签分类", trigger: "blur" }],
      },
      options: [
        {
          value: "选项1",
          label: "性格",
        },
        {
          value: "选项2",
          label: "爱好",
        },
        {
          value: "选项3",
          label: "运动",
        },
      ],
    };
  },
  created() {
    // this.MYtabList();
  },
  methods: {
    async MYtabList() {
      console.log(this.ruleForm.sort, this.ruleForm.name);
      let param = {
        groupby: this.ruleForm.sort,
        name: this.ruleForm.name,
      };
      let res = await MYtabList(param);
      console.log(res);
      if ("添加成功" == res.data) {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.$router.back();
      } else {
        this.$message.error(res.data);
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>

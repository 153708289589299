<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      style="
        margin-top: 30px;
        border-bottom: 1px solid #ccc;
        width: 50%;
        padding-left: 10px;
      "
    >
      <el-form-item label="会员套餐名称" prop="name" required>
        <el-input v-model="ruleForm.name" style="width: 30%"></el-input>
        <span style="font-size: 14px; color: #ccc">(格式:套餐一)</span>
      </el-form-item>
      <el-form-item label="会员套餐价格" prop="appmoneyricep" required>
        <el-input
          v-model="ruleForm.appmoneyricep"
          style="width: 30%"
        ></el-input>
        个,脱单币
      </el-form-item>
      <el-form-item label="会员天数" prop="time" required>
        <el-input
          v-model="ruleForm.time"
          style="width: 30%; margin-left: 28px"
        ></el-input
        >月
      </el-form-item>
    </el-form>
    <el-row style="margin-left: 15%; margin-top: 25px">
      <el-button
        type="primary"
        style="margin-right: 35px"
        @click="MYnewtabOk('ruleForm')"
        >完成</el-button
      >
      <el-button @click="back">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { MYnewtabOk } from "@/api/userManagement";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        appmoneyricep: "",
        time: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入会员套餐名称", trigger: "blur" },
        ],
        appmoneyricep: [
          { required: true, message: "请输入会员套餐价格", trigger: "blur" },
          {
            pattern: "^[1-9]([0-9])*$",
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        time: [
          { required: true, message: "请输入会员天数", trigger: "blur" },
          {
            pattern: "^[1-9]([0-9])*$",
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.$route.query;
  },
  methods: {
    // number(e) {
    //   console.log(e.target.value);
    //   let flag = new RegExp("^[1-9]([0-9])*$").test(e.target.value);
    //   console.log(flag);
    //   if (!flag) {
    //     this.$message({
    //       showClose: true,
    //       message: "请输入正整数",
    //       type: "warning",
    //     });
    //     return;
    //   }
    // },
    MYnewtabOk(formName) {
      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let param = {
            packegname: this.ruleForm.name,
            appmoneyricep: this.ruleForm.appmoneyricep,
            time: this.ruleForm.time,
          };
          let res = await MYnewtabOk(param);
          console.log(res);
          if ("添加成功" == res.data) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.$router.back();
          } else {
            this.$message.error("最多只能添加3个");
            this.$router.back();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>

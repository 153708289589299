import axios from "../plugins/axios";

//课程类别========
//课程类别列表获取
export const getEducationCourseCategoryList = (param) =>
  axios.get(
    "/api/course/getEducationCourseCategoryList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );
//课程类别新增
export const addCourseClasses = (param) =>
  axios.post("/api/course/save", param);
// 编辑
export const editClasses = (param) =>
  axios.get(
    "/api/course/update?id=" +
      param.id +
      "&CourseName=" +
      param.CourseName +
      "&IsOpen=" +
      param.IsOpen
  );
// 删除普通用户
export const deleteUser = (param) =>
  axios.get("/api/course/deleteCourseCategory?ids=" + param.ids);

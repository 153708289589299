<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>普通用户管理</span>
      </div>
      <div class="text item" style="font-size: 14px">普通用户管理。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <!-- <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col> -->
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="" @click="reset">重置</el-button>
      </el-col>
    </el-row>
    <!-- <el-button
      style="background: red; color: #fff; margin-bottom: 10px"
      @click="atchDeletion"
      >批量删除</el-button
    > -->
    <el-table
      :data="tableData"
      style="width: 90%"
      @selection-change="handleSelectionChange"
      border
      v-loading="loading"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column label="序号" type="index" width="80" align="center" />
      <el-table-column
        label="uni-id"
        prop="unionid"
        width="200"
        align="center"
      />

      <el-table-column prop="name" label="昵称" width="200" align="center" />
      <el-table-column
        prop="aboutme"
        label="被关注数量"
        width="150"
        align="center"
      />
      <el-table-column
        prop="phonenum"
        label="手机号"
        width="250"
        align="center"
      />
      <el-table-column
        prop="time"
        label="注册时间"
        width="200"
        align="center"
      />
      <!-- <el-table-column label="启用/禁用" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.state"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="seeMessage(scope.row)">查看信息</el-button>
          <!-- <el-button type="primary" @click="setAdmin(scope.row)"
            >设为红娘</el-button
          > -->
          <!-- <el-button type="primary" @click="setAdmin1(scope.row)"
            >设为总管理员</el-button
          >
          <el-button type="danger" @click="deleteUser(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  getUserList,
  updateUserStatus,
  // deleteUser,
  // setAdminOrBindAdminUser,
  // atchDeletion,
} from "@/api/userManagement";
export default {
  data() {
    return {
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      state: 1,
      ids: [],
      loading: false,
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
      selectedRows: [],
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    seeMessage(row) {
      console.log(row);
      this.$router.push({ path: "/trainPersonal", query: { id: row.id } });
    },
    // study() {
    //   this.$router.push("/checkStudyData");
    // },
    // 获取普通用户列表
    async getUserList() {
      this.loading = true;
      let param = {
        name: this.searchText, //名称
        state: this.selectedUnit, //状态
        starNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await getUserList(param);
      console.log(res, "8888888888888");
      this.loading = false;
      if (res.status == "200") {
        this.tableData = res.data.list;
        // res.data.list.forEach((item) => {
        //   item.intime = item.intime.slice(0, 10);
        // });
        console.log(this.tableData);
        this.total = res.data.total;
      }
      console.log(this.tableData, "res");
    },
    // 搜索
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getUserList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
      this.getUserList();
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.getUserList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      console.log(selectedRows);
      this.selectedRows = selectedRows;
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row, row.recovery);
      let res = await updateUserStatus({ id: row.id, state: row.state });
      console.log(res);
      if (res.data == "ok") {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getUserList();
      } else {
        this.$message.error("操作失败");
      }
      console.log(res, "changeStatus");
    },

    // 删除按钮
    // async deleteUser(row) {
    //   console.log("deleteUser", row);
    //   this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       let res = await deleteUser({
    //         id: row.id,
    //       });
    //       console.log("deleteUser", res);
    //       if (res.data == "注销成功") {
    //         this.$message({
    //           type: "success",
    //           message: "删除成功",
    //         });
    //         this.getUserList();
    //       }
    //     })
    //     .catch(() => {
    //       // this.$message({
    //       //   type: "info",
    //       //   message: "已取消删除",
    //       // });
    //     });
    // },
    // 批量删除
    // async atchDeletion() {
    //   var ids = this.selectedRows.map((item) => {
    //     return item.id;
    //   });
    //   this.$confirm("此操作将删除这些用户, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       let res = await atchDeletion({
    //         id: ids,
    //       });
    //       console.log(res);
    //       if ("注销成功" == res.data) {
    //         this.$message({
    //           type: "success",
    //           message: "删除成功",
    //         });
    //         this.getUserList();
    //       }
    //     })
    //     .catch(() => {
    //       // this.$message({
    //       //   type: "info",
    //       //   message: "已取消删除",
    //       // });
    //     });
    // },
    // 设为红娘
    // async setAdmin(row) {
    //   console.log("setAdmin", row);
    //   let res = await setAdminOrBindAdminUser({
    //     id: row.id,
    //     level: 2,
    //   });
    //   console.log(res);
    //   if (res.data == "添加成功") {
    //     this.$message({
    //       type: "success",
    //       message: "设置成功",
    //     });
    //     this.getUserList();
    //   }
    //   console.log("setAdmin", res);
    // },
    // // 设为管理员
    // async setAdmin1(row) {
    //   console.log("setAdmin", row);
    //   let res = await setAdminOrBindAdminUser({
    //     id: row.id,
    //     level: 1,
    //   });
    //   console.log(res);
    //   if (res.data == "添加成功") {
    //     this.$message({
    //       type: "success",
    //       message: "设置成功",
    //     });
    //     this.getUserList();
    //   }
    //   console.log("setAdmin", res);
    // },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>
